import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PickTextColorBasedOnBgColorSimple } from '@shared/AppFunctions';
import { ProfileTagService } from '@shared/inbox-service/profile-tag.service';
import {
  CreateLinkedInProfileTagDto,
  LinkedInProfileTagDto,
  UpdateProfileTagDto,
} from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';

@Component({
  templateUrl: './create-tag-dialog.component.html',
})
export class CreateTagDialogComponent {
  labelColors: string[] = [
    '#1c64f2',
    '#31c48d',
    '#7e3af2',
    '#e645d5',
    '#f03d3d',
    '#28c9f1',
    '#cbc00d',
    '#f38316',
  ];

  isEditMode: boolean = false;
  model: LinkedInProfileTagDto = new LinkedInProfileTagDto({
    colorHex: '#1c64f2',
    displayName: '',
    id: undefined,
  });
  saving: boolean;

  private readonly tagNameMaxLength = 25;

  constructor(
    private _dialogRef: MatDialogRef<CreateTagDialogComponent>,
    public _tagService: ProfileTagService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _model: LinkedInProfileTagDto,
  ) {
    if (this._model) {
      this.model = _.clone(this._model);
      this.isEditMode = true;
    }
  }

  async save() {
    var createdTag: LinkedInProfileTagDto;

    this.saving = true;

    if (this.model.id) {
      await this._tagService.updateTag(
        new UpdateProfileTagDto({
          tagId: this.model.id,
          colorHex: this.model.colorHex,
          displayName: this.model.displayName,
        }),
      );
      createdTag = this.model;
    } else {
      createdTag = await this._tagService.createTag(new CreateLinkedInProfileTagDto(this.model));
    }

    this.saving = false;

    this.close(createdTag);
  }

  close(result: LinkedInProfileTagDto = null) {
    this._dialogRef.close(result);
  }

  pickTextColorBasedOnBgColorSimple(bgColor) {
    return PickTextColorBasedOnBgColorSimple(bgColor);
  }

  get isSaveButtonDisabled(): boolean {
    return (
      this.saving ||
      !this.model.displayName ||
      this.model.displayName.length > this.tagNameMaxLength
    );
  }
}
