<ng-container *ngIf="profile">
  <div class="flex gap-3 items-center mt-7">
    <user-avatar
      [imageUrl]="profile.imageUrl"
      [firstName]="profile.firstName"
      [lastName]="profile.lastName"
      [hasDynamicFont]="false"
      [size]="50"
      [customFontSize]="20"
      class="flex-shrink-0"></user-avatar>
    <div class="flex flex-col flex-grow justify-between">
      <div class="flex justify-between">
        <span class="text-lg font-semibold text-gray-700">
          {{ profile.firstName + ' ' + profile.lastName }}
        </span>
        <linkedin-profile-url [profile]="profile"></linkedin-profile-url>
      </div>
      <div class="flex justify-between">
        <email-enrich-button
          class="w-full"
          [style.padding-right]="'5px'"
          [profile]="profile"
          [showAsButton]="false"
          [disableEmailTooltip]="true"
          [enableEmailEditing]="true"></email-enrich-button>
      </div>
    </div>
  </div>
  <div *ngIf="showActionButtons" class="flex flex-col items-center px-7">
    <div class="flex mt-2">
      <crm-button>
        <button
          heyReachButton
          buttonType="icon"
          (click)="onExportToCrmClick()"
          matTooltip="Export to CRM">
          <mat-icon
            svgIcon="custom:export_to_crm"
            class="icon-size-5 ml-0.5 text-secondary"></mat-icon>
        </button>
      </crm-button>
      <button
        heyReachButton
        buttonType="icon"
        matTooltip="Add to campaign"
        (click)="openAddToCampaignDialog()"
        *ngIf="accountId">
        <mat-icon svgIcon="custom:send_campaign" class="icon-size-5"></mat-icon>
      </button>
      <button
        heyReachButton
        buttonType="icon"
        matTooltip="Add to list"
        (click)="openAddToListDialog()">
        <mat-icon svgIcon="custom:add_to_list" class="icon-size-5"></mat-icon>
      </button>
    </div>
  </div>
  <div class="py-5 pr-3">
    <div class="mt-2">
      <div class="mb-4">
        <tags-list
          [selectedProfiles]="[profile]"
          [selectedAll]="false"
          [isSingleTagView]="false"
          (onAssignTag)="onAssignTag($event)"
          (onUnassignTag)="onUnassignTag($event)"
          (onUpdateTagChange)="updateTag($event)"
          (onTagDeleted)="onTagDeleted.emit($event)"></tags-list>
      </div>
      <div
        #leadActivityDiv
        class="border-2 border-secondary-muted rounded-lg p-4 mb-4 max-h-100 overflow-y-auto"
        (scroll)="onLeadActivityScroll()">
        <div class="font-bold text-lg mb-3">Activity</div>
        <div
          *ngIf="!showLeadActivity"
          class="flex justify-center font-bold cursor-pointer"
          (click)="loadNewLeadActivity()">
          Show more
        </div>
        <div *ngIf="showLeadActivity" class="flex flex-col">
          <div
            *ngFor="let activity of leadActivity$ | async; let index = index"
            class="flex flex-col pt-3"
            [ngClass]="{
              'border-t': index !== 0,
              'border-pale': index !== 0,
              'mt-3': index !== 0
            }">
            <ng-container
              *ngIf="activity.actionType | leadActivityActionIsFromSender; else leadAction">
              <div class="text-lg font-medium">
                {{ activity.actionType | leadActivityAction: true }}
              </div>
              <div class="flex gap-x-4 items-center text-secondary flex-wrap">
                <div class="flex items-center gap-2">
                  <mat-icon svgIcon="custom:linkedin" class="icon-size-4"></mat-icon>
                  <span>{{ activity.linkedInAccountFullName }}</span>
                </div>
                <div class="flex items-center gap-2">
                  <mat-icon svgIcon="custom:send_campaign" class="icon-size-4"></mat-icon>
                  <span>{{ activity.campaignName }}</span>
                </div>
              </div>
              <div class="text-secondary">
                {{ activity.actionTime | shortTimeAgo }}
              </div>
            </ng-container>
            <ng-template #leadAction>
              <div class="text-lg">
                <span class="font-medium">{{ profile.fullName }}</span>
                {{ activity.actionType | leadActivityAction }}
              </div>
              <div class="flex items-center text-secondary gap-2">
                <mat-icon svgIcon="custom:send_campaign" class="icon-size-4"></mat-icon>
                <span>{{ activity.campaignName }}</span>
              </div>
              <div class="text-secondary">
                {{ activity.actionTime | shortTimeAgo }}
              </div>
            </ng-template>
          </div>
          <div
            *ngIf="showLeadActivity && !(leadActivityLoading$ | async)"
            (click)="showLeadActivity = false"
            class="flex justify-center font-bold cursor-pointer my-2">
            Show less
          </div>
        </div>
        <div *ngIf="leadActivityLoading$ | async" class="flex justify-center mt-3">
          <progress-spinner color="primary" [diameter]="10"></progress-spinner>
        </div>
        <div
          *ngIf="
            showLeadActivity &&
            !(leadActivityLoading$ | async) &&
            (leadActivity$ | async).length === 0
          "
          class="text-secondary">
          No activity in any campaigns
        </div>
      </div>
      <info-card-display
        *ngIf="profile.summary"
        [title]="'Headline'"
        [summary]="profile.summary"></info-card-display>
      <info-card-display
        *ngIf="profile.about"
        [title]="'About'"
        [summary]="profile.about"></info-card-display>
      <info-card-display
        *ngIf="profile.companyName"
        [title]="'Company'"
        [summary]="profile.companyName"></info-card-display>
      <info-card-display
        *ngIf="profile.location"
        [title]="'Address'"
        [summary]="profile.location"></info-card-display>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!profile">
  <div class="flex flex-col items-center mt-8 px-7">
    <user-avatar [size]="9"></user-avatar>
    <div class="mt-4 text-lg font-medium text-center">LinkedIn Member</div>
  </div>
</ng-container>
