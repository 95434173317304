import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  host: {
    class: 'font-medium rounded-lg px-5 py-6',
  },
  standalone: true,
})
export class BannerComponent {
  @Input() color: BannerColor = 'primary';
  @Input() border: boolean;

  public classes: string;

  private colorClassMap: Record<BannerColor, string> = {
    primary: `text-primary bg-primary-muted border-primary`,
    danger: `text-warn bg-warn-translucent border-warn`,
    warn: `bg-warning-muted border-warning`,
  };

  @HostBinding('class') get classList() {
    return {
      'border-2': this.border,
      ...this.colorClassMap[this.color]
        .split(' ')
        .reduce((acc, cls) => ({ ...acc, [cls]: true }), {}),
    };
  }
}

export type BannerColor = 'primary' | 'danger' | 'warn';
