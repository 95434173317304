import { environment } from '../environments/environment';

export class AppConsts {
  static remoteServiceBaseUrl: string;
  static appBaseUrl: string;
  static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

  static stripeApiKey: string;

  static localeMappings: any = [];

  static extensionId: string;
  static extensionDownloadUrl: string;
  static isProdEnv: boolean;

  static readonly userManagement = {
    defaultAdminUserName: 'admin',
  };

  static readonly localization = {
    defaultLocalizationSourceName: 'Spremo',
  };

  static readonly authorization = {
    encryptedAuthTokenName: 'enc_auth_token',
  };

  // User interface consts
  static readonly minimumLettersToSearch: number = 3;
  static readonly searchInputDebounceTimeMillis: number = 350;
  static readonly minimumDaysForDelayComponent: number = 1;
  static readonly minimumHoursForDelayComponent: number = 3;

  // Extension info

  // Payment success & cancellation
  static readonly paymentEventRedirectTimeMilis = 3000;

  static readonly HeyReachEventType = {
    CONNECTIONS_SENT: 'Connections Sent',
    CONNECTIONS_ACCEPTED: 'Connections Accepted',
    MESSAGES_SENT: 'Messages Sent',
    MESSAGE_REPLIES: 'Message Replies',
    INMAILS_SENT: 'InMails Sent',
    INMAIL_REPLIES: 'InMail Replies',
  };

  static readonly requestDebounceTimeMilis = 200;

  //SignalR
  static signalRServiceBaseUrl: string;
  static inboxConnectionHeartbeatIntervalMs = 60000; // 1 minute

  static isDev(): boolean {
    return environment.name === 'localhost' || environment.name === 'AWSDev';
  }

  static isStaging(): boolean {
    return environment.name === 'AWSStaging';
  }

  // ChurnKey
  static readonly churnKeyAppId = '5d5zqqddf';
  static readonly churnkey_offer_no_roi_guid = 'f22ff446-f118-4c46-b587-776f30bc0c62';
  static readonly churnkey_offer_support_guid = 'f64533bf-d790-4ddf-80c0-0f8492b5e78d';
  static readonly churnkey_cancel_confirm_step_guid = '3cd89a58-5a3e-4d98-a8fb-91335b1beba4';

  // Calendly
  static readonly CustomerSuccessCalendlyUrl = 'https://calendly.com/heyreach/customer-success';
  static readonly CustomerSupportCalendlyUrl = 'https://calendly.com/ebru-heyreach/30min';
  static readonly SalesCalendlyUrl = 'https://calendly.com/d/cmgp-qrd-cr6';

  static readonly UnlimitedSeatsThreshold = 500;
}
