import { Injectable } from '@angular/core';
import {
  ApplicationInfoDto,
  GetCurrentLoginInformationsOutput,
  SessionServiceProxy,
  SubscriptionDto,
  TenantLoginInfoDto,
  UserLoginInfoDto,
} from '@shared/service-proxies/service-proxies';
import Gleap from 'gleap';
import { BehaviorSubject } from 'rxjs';

declare var __ls: any;
declare global {
  interface Window {
    birdeatsbug: any;
    intercomSettings: any;
    Intercom: any;
    usetifulTags: any;
    churnkey: any;
    dataLayer: any;
    F2FSDK: any;
  }
}

@Injectable()
export class AppSessionService {
  userCameFromStripe: boolean = false;
  private _user: UserLoginInfoDto;
  private _subscription: SubscriptionDto;
  private _tenant: TenantLoginInfoDto;
  private _application: ApplicationInfoDto;
  private _usedPromoCode: boolean;
  private _numberOfAvailableSeats: number;
  private _totalNumberOfSeats: number;

  private _subscriptionSubject$: BehaviorSubject<SubscriptionDto | undefined> = new BehaviorSubject<
    SubscriptionDto | undefined
  >(undefined);
  subscription$ = this._subscriptionSubject$.asObservable();

  constructor(private _sessionService: SessionServiceProxy) {}

  get user(): UserLoginInfoDto {
    return this._user;
  }

  get subscription(): SubscriptionDto {
    return this._subscription;
  }

  get tenant(): TenantLoginInfoDto {
    return this._tenant;
  }

  get isAdminTenant() {
    return !this._tenant.name && !this._tenant.tenancyName && this._tenant.id === 0;
  }

  get isAdminUser() {
    return this._user.roleNames.map((rn) => rn.toLowerCase()).includes('admin');
  }

  get application(): ApplicationInfoDto {
    return this._application;
  }

  get usedPromoCode(): boolean {
    return this._usedPromoCode;
  }

  set usedPromoCode(value: boolean) {
    this._usedPromoCode = value;
  }

  get userId(): number {
    return this.user ? this.user.id : null;
  }

  get tenantId(): number {
    return this.tenant ? this.tenant.id : null;
  }

  get numberOfActiveSeats(): number {
    return this._totalNumberOfSeats | 0;
  }

  get numberOfAvailableSeats(): number {
    return this._numberOfAvailableSeats | 0;
  }

  init(isRefresh?: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._sessionService
        .getCurrentLoginInformations()
        .toPromise()
        .then(
          (result: GetCurrentLoginInformationsOutput) => {
            this._application = result.application;
            this._user = result.user;
            this._tenant = result.tenant;
            this._subscription = result.subscription;
            this._subscriptionSubject$.next(result.subscription);
            this._usedPromoCode = result.usedPromoCode;
            this._numberOfAvailableSeats = result.numberOfAvailableSeats;
            this._totalNumberOfSeats = result.totalNumberOfSeats;
            if (this._user && !isRefresh) {
              // GTM data
              try {
                let subscriptionType =
                  this._subscription?.subscriptionType?.displayName || 'unknown';
                if (this._subscription?.isTrial) subscriptionType = 'free';

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'login',
                  user_id: this._user.id,
                  name: this._user.name,
                  email: this._user.emailAddress,
                  tenant_id: this._tenant.id,
                  tenant_name: this._tenant.name,
                  pricing_plan: subscriptionType,
                });
              } catch (error) {
                console.error(`Error sending GTM login event: ${error}`);
              }

              // F2F data
              try {
                let subscriptionType =
                  this._subscription?.subscriptionType?.displayName || 'unknown';
                if (this._subscription?.isTrial) subscriptionType = 'free';
                if (this._subscription?.appSumo) subscriptionType = 'appSumo';

                let f2f_data = {
                  tenant_id: this._tenant.id,
                  user_id: this._user.id,
                  name: this._user.name,
                  email: this._user.emailAddress,
                  tenant_name: this._tenant.name,
                  pricing_plan: subscriptionType,
                  is_appsumo: `${this._subscription?.appSumo}`,
                };
                window.F2FSDK.identify(f2f_data);
              } catch (error) {
                console.error(error);
              }

              // Intercom data
              try {
                if (window.intercomSettings) {
                  window.intercomSettings.name = this._user.name;
                  window.intercomSettings.email = this._user.emailAddress;
                  window.intercomSettings['Surname'] = this._user.surname;
                  window.intercomSettings['Tenancy Name'] = this._tenant.tenancyName;
                  window.intercomSettings['Tenant Name'] = this._tenant.name;
                  window.intercomSettings['Company'] = this._tenant.name;
                  window.intercomSettings['UserId'] = this._user.id;
                  window.intercomSettings['TenantId'] = this._tenant.id;
                  window.intercomSettings['Is Admin'] = this.isAdminUser;
                  window.intercomSettings['Is Free Trial'] = this._subscription?.isTrial ?? false;
                  window.intercomSettings['Subscription Type'] =
                    this._subscription?.subscriptionType?.displayName;
                  window.intercomSettings['Is Subscribed'] =
                    this._subscription != null && !this._subscription?.isTrial;
                  window.intercomSettings['AppSumo'] = this._subscription?.appSumo ?? false;
                  window.intercomSettings['Has Active Subscription'] =
                    this._subscription != null && this._subscription.isActive;
                  window.intercomSettings['Free Trial Start Date'] = new Date(
                    this._tenant.startedFreeTrialDate,
                  ).toISOString();
                  window.intercomSettings['Total Seats'] = this._totalNumberOfSeats ?? 0;
                  window.intercomSettings['Available Seats'] = this._numberOfAvailableSeats ?? 0;
                  window.intercomSettings['Registration Date'] = this._user.creationTime;
                  window.intercomSettings['StripeURL'] = this._tenant.stripeCustomerId
                    ? `https://dashboard.stripe.com/customers/${this._tenant.stripeCustomerId}`
                    : `https://dashboard.stripe.com/search?query=${this._user.emailAddress}`;

                  window.Intercom('update');
                }
              } catch (error) {
                console.error(error);
              }

              try {
                if (__ls) {
                  __ls('identify', {
                    name: this._user.name,
                    email: this._user.emailAddress,
                  });

                  __ls('setCustomParams', {
                    params: {
                      tenant_id: this._tenant.id,
                      is_admin: this._user.roleNames
                        .map((rn) => rn.toLowerCase())
                        .includes('admin'),
                      is_trial: this._subscription?.isTrial,
                      subscription_type: this._subscription?.subscriptionType?.displayName,
                      is_paying:
                        this._subscription != null &&
                        !this._subscription?.isTrial &&
                        this._subscription?.isActive,
                      is_appsumo: this._subscription?.appSumo,
                      total_seats: this._totalNumberOfSeats,
                      free_seats: this._numberOfAvailableSeats,
                    },
                  });
                }
              } catch (error) {
                console.error(error);
              }

              // Bird eats bug data
              try {
                if (window?.birdeatsbug) {
                  window.birdeatsbug.setOptions({
                    user: {
                      id: this._user.id,
                      email: this._user.emailAddress,

                      // Optional properties
                      fullName: this._user.name,
                      tenantId: this._tenant.id,
                      tenant: this._tenant.name,
                    },
                  });
                }
              } catch (error) {
                console.log('BirdEatsBug not loaded correctly');
                console.error(error);
              }

              try {
                window.usetifulTags = {
                  userId: this.user.id,
                  emailAddress: this.user.emailAddress,
                  name: this.user.name,
                };
              } catch (error) {
                console.log('Usetiful not loaded correctly');
                console.error(error);
              }
            }

            resolve(true);
          },
          (err) => {
            reject(err);
          },
        );
    });
  }
}
