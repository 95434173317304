import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserInterfaceService {
  private headerHeight: number = 0;
  private footerHeight: number = 0;
  private tableRowHeightPx: number = 30;
  private substructedPaddingPx: number = 165;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private setHeaderHeight() {
    const headerElement = document.getElementById('main-header-container');
    if (headerElement) {
      this.headerHeight = headerElement.offsetHeight;
    }
  }

  private setFooterHeight() {
    const footerElement = document.getElementById('main-footer-container');
    if (footerElement) {
      this.footerHeight = footerElement.offsetHeight;
    }
  }

  private calculateHeaderAndFooterHeight() {
    this.setHeaderHeight();
    this.setFooterHeight();
  }

  getTotalElementsHeight() {
    this.calculateHeaderAndFooterHeight();
    return this.headerHeight + this.footerHeight;
  }

  get getTableRowHeightPx(): number {
    return this.tableRowHeightPx;
  }

  calculateHeight() {
    this.calculateHeaderAndFooterHeight();
    return window.innerHeight - this.getTotalElementsHeight() - this.substructedPaddingPx;
  }

  toggleIntercom(renderer: Renderer2, show: boolean): void {
    const intercomLauncher = this.document.getElementsByClassName(
      'intercom-launcher',
    )[0] as HTMLElement;
    if (intercomLauncher) {
      if (show) {
        renderer.removeStyle(intercomLauncher, 'display');
      } else {
        renderer.setStyle(intercomLauncher, 'display', 'none');
      }
    }
  }

  clickIntercom(event: MouseEvent): void {
    let intercomLauncher = this.document.getElementsByClassName(
      'intercom-launcher',
    )[0] as HTMLElement;
    if (intercomLauncher) {
      intercomLauncher.click();
    } else {
      intercomLauncher = this.document.querySelectorAll(
        'iframe.intercom-launcher-frame',
      )[0] as HTMLElement;
      if (intercomLauncher) {
        const rect = intercomLauncher.getBoundingClientRect();
        this.showGuidingArrow(
          { x: event.clientX, y: event.clientY, size: 100 },
          { x: rect.left + rect.width / 2, y: rect.top, size: 100 },
        );
      }
    }
  }

  showGuidingArrow(pos1: GuidingArrowPosition, pos2: GuidingArrowPosition) {
    this.cleanUpGuidingArrow();

    const background = this.document.createElement('div');
    background.id = 'guiding-arrow-background';
    background.style.position = 'fixed';
    background.style.top = '0';
    background.style.left = '0';
    background.style.width = '100vw';
    background.style.height = '100vh';
    background.style.backgroundColor = 'rgba(0, 0, 0, 0.0)';
    background.style.transition = 'background-color 500ms';
    background.style.zIndex = '1000';

    const arrow = this.document.createElement('div');
    arrow.id = 'guiding-arrow';
    arrow.style.position = 'fixed';
    arrow.style.width = `${pos1.size}px`;
    arrow.style.height = `${pos1.size}px`;
    arrow.style.background = 'url("assets/icons/white-arrow.svg") no-repeat center';
    arrow.style.backgroundSize = 'contain';
    arrow.style.opacity = '0';
    arrow.style.left = `${pos1.x - pos1.size * 0.5}px`;
    arrow.style.top = `${pos1.y}px`;
    arrow.style.transition =
      'transform 1s ease-in-out, left 1s ease-in-out, top 1s ease-in-out, opacity 500ms, width 1s ease-in-out, height 1s ease-in-out';
    arrow.style.zIndex = '1001';

    this.document.body.appendChild(background);
    this.document.body.appendChild(arrow);

    const deltaX = pos2.x - pos1.x;
    const deltaY = pos2.y - pos1.y;
    const radian = Math.atan2(deltaY, deltaX);
    const arrowAngle = radian * (180 / Math.PI) + 180;
    arrow.style.transform = `rotate(${arrowAngle}deg)`;

    setTimeout(() => {
      background.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    });

    setTimeout(() => {
      arrow.style.opacity = '1';
      arrow.style.left = `${pos2.x - pos2.size * 0.5}px`;
      arrow.style.top = `${pos2.y - pos2.size}px`;
      arrow.style.width = `${pos2.size}px`;
      arrow.style.height = `${pos2.size}px`;
    }, 100);

    setTimeout(() => {
      arrow.style.opacity = '0';
    }, 800);

    setTimeout(() => {
      background.style.backgroundColor = 'rgba(0, 0, 0, 0.0)';
      arrow.style.opacity = '0';
    }, 1100);

    setTimeout(() => {
      this.cleanUpGuidingArrow();
    }, 1600);
  }

  private cleanUpGuidingArrow() {
    const background = this.document.getElementById('guiding-arrow-background');
    const arrow = this.document.getElementById('guiding-arrow');

    if (background) {
      background.remove();
    }
    if (arrow) {
      arrow.remove();
    }
  }
}

export interface GuidingArrowPosition {
  x: number;
  y: number;
  size: number;
}
