import { Component, Input } from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { LinkedInAccountDto } from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'linkedin-account-search-locked-indicator',
  templateUrl: './account-search-locked-indicator.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedInAccountSearchLockedIndicator {
  @Input() account: LinkedInAccountDto;

  getTooltipText(): string {
    if (!this.account) {
      return '';
    }
    if (this.account.isSearchLocked && this.account.connectionRequestLocked) {
      return `
        This account has reached the search limit provided by LinkedIn.
        Due to this it will retrieve a lower number for results in the searches.
        We recommend that you don't use this account in new searches until this limit has reset.
        The account has also reached the search limit provided by LinkedIn. Due to this it will retrieve a lower number for results in the searches.
        We recommend that you don't use this account in new searches until this limit has reset.`;
    }
    if (this.account.isSearchLocked && this.account.connectionRequestCooldown) {
      return `
        This account has reached the search limit provided by LinkedIn.
        Due to this it will retrieve a lower number for results in the searches.
        We recommend that you don't use this account in new searches until this limit has reset.
        We have also detected that this account is near its connection request limit due to its health.
        In order to protect the account we will be making a lower number of action from this account until the end of the week.`;
    }
    if (this.account.connectionRequestLocked) {
      return `
      This account has reached its connection request limit for the week.
      In order to protect the account, we have put in in a cooldown state and we will not be sending any more connection requests until the end of the week.
      The account will get out of the cooldown period at the start of the next week.`;
    }
    if (this.account.connectionRequestCooldown) {
      return `
        We have detected that this account is near its connection request limit due to its health.
        In order to protect the account we will be making a lower number of action from this account until the end of the week.`;
    }
    if (this.account.isSearchLocked) {
      return `This account has reached the search limit provided by LinkedIn.
      Due to this it will retrieve a lower number for results in the searches.
      We recommend that you don't use this account in new searches until this limit has reset.`;
    }
    if (this.account.isInMailLocked) {
      return `Your monthly InMail limit has been reached. Your account will continue sending InMails on the 1st of next month.`;
    }

    return '';
  }
}
