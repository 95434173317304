import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';
import { DisplayedScreenInLogin } from '@app/linkedin-accounts/linked-in-accounts.service';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  imports: [CommonModule, HeyReachButtonDirective],
  selector: 'app-2fa-tutorial',
  templateUrl: '2fa-tutorial.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'flex flex-col gap-y-4 font-medium text-secondary',
  },
})
export class TwoFactorAuthTutorialComponent {
  protected isWhiteLabel: boolean = this._whiteLabelService.notUs;

  @Output() screenToBeDisplayed: EventEmitter<DisplayedScreenInLogin> =
    new EventEmitter<DisplayedScreenInLogin>();

  constructor(private _whiteLabelService: WhiteLabelService) {}

  showIntercomWidget(): void {
    window.Intercom('show');
  }
}
