import {
  ConnectLinkedInAccountInputDto,
  InboxScrapeConfiguration,
  OwnProxyDto,
} from '@shared/service-proxies/service-proxies';

export const getInboxConfigurations = (companyName: string) => [
  {
    value: InboxScrapeConfiguration._0,
    text: 'Track and import all LinkedIn conversations',
  },
  // {
  //   value: InboxScrapeConfiguration._1,
  //   text: 'Only future conversations (from now)',
  // },
  {
    value: InboxScrapeConfiguration._2,
    text: `Track only conversations started from ${companyName}`,
  },
];

export class ConnectAccountConsts {
  static isAccountAndPasswordValid(account: ConnectLinkedInAccountInputDto): boolean {
    return (
      account.username !== undefined &&
      account.password !== undefined &&
      account.username.length > 0 &&
      account.password.length > 0
    );
  }

  static isInboxConfigurationValid(account: ConnectLinkedInAccountInputDto): boolean {
    return (
      account.inboxScrapeConfiguration !== undefined && account.inboxScrapeConfiguration !== null
    );
  }

  static isAccountAndInboxValid(account: ConnectLinkedInAccountInputDto): boolean {
    return this.isAccountAndPasswordValid(account) && this.isInboxConfigurationValid(account);
  }

  static is2faValid(account: ConnectLinkedInAccountInputDto): boolean {
    return (
      account.twoFactorAuthenticationSecretToken !== undefined &&
      account.twoFactorAuthenticationSecretToken.length > 0
    );
  }

  static isPinValid(pin: string): boolean {
    if (!pin || pin.length !== 6) {
      return false;
    }

    const regexp = new RegExp('^[0-9]*$');
    return regexp.test(pin);
  }
}
