import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { WhiteLabelService } from '@app/white-label.service';
import { AppSessionService } from '@shared/session/app-session.service';
import { MatIconModule } from '@angular/material/icon';
import { ProxySelectionComponent } from '@shared/components/user-interface/proxy-selection/proxy-selection.component';
import { CustomProxyService } from '../custom-proxy-service';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-connect-account-proxy',
  templateUrl: './connect-account-proxy.component.html',
  standalone: true,
  imports: [MatIconModule, ProxySelectionComponent, NgIf, AsyncPipe],
})
export class ConnectAccountProxyComponent {
  isAgency: boolean = this._appSession.subscription?.subscriptionType?.isAgency;
  companyName = this._whiteLabelService.companyName;

  constructor(
    protected _customProxyService: CustomProxyService,
    private _whiteLabelService: WhiteLabelService,
    private _appSession: AppSessionService,
  ) {}
}
