<h3 class="text-lg font-semibold text-third-background-primary-text">
  Follow the steps to enable LinkedIn 2FA in 60 seconds
</h3>
<p>
  <strong class="text-third-background-primary-text">Step 1:</strong>
  Go to LinkedIn, click on your profile picture in the top right corner
</p>
<p>
  <strong class="text-third-background-primary-text">Step 2:</strong>
  From the dropdown, open
  <strong>Settings & Privacy</strong>
</p>
<p>
  <strong class="text-third-background-primary-text">Step 3:</strong>
  Choose
  <strong>Sign in & security</strong>
  and click
  <strong>two-step verification.</strong>
</p>
<p>
  <strong class="text-third-background-primary-text">Step 4:</strong>
  Click
  <strong>Set up,</strong>
  choose Authenticator app as your preferred verification method from the dropdown menu, and click
  <strong>Continue</strong>
  to enable two-step verification
</p>
<p>
  <strong class="text-third-background-primary-text">Step 5:</strong>
  Copy your
  <strong>2FA secret key</strong>
  and make sure to save it in a place only you can access
</p>
<img src="assets/images/login/2fa-tutorial.png" />
<p *ngIf="!isWhiteLabel" class="text-third-background-primary-text">
  Need help? Check the
  <a
    class="hover:underline"
    href="https://help.heyreach.io/en/articles/10665739-how-to-activate-the-infinite-login"
    target="_blank">
    step-by-step guide
  </a>
  or reach out to our
  <a class="hover:underline" href="javascript:void(0)" (click)="showIntercomWidget()">
    customer support team
  </a>
</p>
<button
  class="w-full"
  heyReachButton
  (click)="screenToBeDisplayed.emit('2fa-login')"
  buttonType="primary">
  Next
</button>
