import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { AddonFeaturesService } from '@shared/email-credits/email-credits.service';
import { StripeServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppSessionService } from '@shared/session/app-session.service';
import { AppUrlService } from '@shared/nav/app-url.service';
import { Router } from '@angular/router';
import { WhiteLabelService } from '@app/white-label.service';
import { LinkedinListsService } from '@app/linkedin-lists/services/linkedin-lists.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnDestroy {
  get canReportBug(): boolean {
    return window?.birdeatsbug?.isBrowserSupported;
  }

  get appSumoPrice(): number {
    return this._session.subscription?.subscriptionType?.name == 'heyreach_tier1' ? 429 : 249;
  }

  isAdmin: boolean = false;

  notUs = this.whiteLabelService.notUs;
  private destroy$ = new Subject<void>();
  constructor(
    public _session: AppSessionService,
    public _emailCredits: AddonFeaturesService,
    private _stripeService: StripeServiceProxy,
    private _cd: ChangeDetectorRef,
    public urlService: AppUrlService,
    private whiteLabelService: WhiteLabelService,
    private _router: Router,
    private _linkedInListsService: LinkedinListsService,
  ) {
    this._emailCredits.onChange.subscribe((x) => {
      this._cd.detectChanges();
    });

    this.isAdmin = this._session.tenantId == null || this._session.tenantId == 0;
  }

  reportBug() {
    window?.birdeatsbug?.trigger();
  }

  gotToAppSumo() {
    window.open(`https://appsumo.com/products/heyreach`, '_blank');
  }

  goToAdminPanel(): void {
    this._router.navigate(['/admin']);
  }

  upgradeLTD() {
    this._stripeService.createAppSumoUpgradeSession().subscribe((sessionId) => {
      var stripe = new Stripe(AppConsts.stripeApiKey);
      stripe.redirectToCheckout({ sessionId: sessionId });
    });
  }

  navigateBack() {
    this._router.navigate([this.urlService.backActionUrl]);
  }

  /**
   * This function is used to dynamically set a string and call it by using the available and bindable functions from customHeaderFunctions object
   * For example here I want to call editListName() function, which is passed as string as customActionName
   */

  private customHeaderFunctions: { [key: string]: () => void } = {
    editListName: this.editListName.bind(this),
  };

  customIconAction(customActionName?: string) {
    if (customActionName && this.customHeaderFunctions[customActionName]) {
      this.customHeaderFunctions[customActionName]();
    }
  }

  editListName() {
    this._linkedInListsService.editListName();
    this._linkedInListsService.list$.pipe(takeUntil(this.destroy$)).subscribe((list) => {
      this.urlService.pageName = list.name;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
