﻿
<div class="w-full mt-2 flex px-15 text-dark text-3xl justify-center font-extrabold text-center">
  <span>Invite your team to connect their LinkedIn accounts</span>
</div>
<p class="text-center text-base font-medium text-secondary mt-2 mb-5">
  They’ll all have free accounts together with you to test {{ companyName }}
</p>
<div class="mt-4">
  <div
    class="default-border w-full block mt-2 rounded-lg pl-3.5 py-2 cursor-text"
    (click)="onTextAreaClick()">
    <tag-input
      class="h-48"
      [(ngModel)]="inviteEmails"
      [addOnBlur]="true"
      [ripple]="false"
      [blinkIfDupe]="true"
      [animationDuration]="{ enter: '0ms', leave: '0ms' }"
      [errorMessages]="errorMessages"
      [validators]="validators"
      [disabled]="invitationsInProgress"
      [separatorKeyCodes]="[32, 188, 186, 13, 9]"
      placeholder=""
      secondaryPlaceholder=""
      theme="minimal"></tag-input>
  </div>
</div>
<div class="mt-7 w-full flex flex-col items-center sm:flex-row sm:justify-between">
  <button class="w-auto float-left" (click)="skipStep()" [disabled]="invitationsInProgress">
    <div class="flex items-center">
      <span class="ml-0 mt-2 text-base underline text-primary font-medium">Dismiss</span>
    </div>
  </button>
  <button
    heyReachButton
    buttonType="primary"
    class="ml-3 w-auto float-right"
    (click)="goToNextStep()"
    [disabled]="isInviteButtonDisabled">
    <div class="flex items-center" [loading]="invitationsInProgress">
      <span class="mx-6 text-base">Send invitations</span>
    </div>
  </button>
</div>
