import { Pipe, PipeTransform } from '@angular/core';

import { ColorService } from '@shared/colors/color.service';

@Pipe({ name: 'generateLighterColor' })
export class GenerateLighterColorPipe implements PipeTransform {
  constructor(private _colorService: ColorService) {}

  transform(hexColor: string): string {
    return this._colorService.generateLighterColor(hexColor);
  }
}
