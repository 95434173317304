import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[heyreachButton]',
})
export class HeyReachButtonDirective implements OnInit {
  @Input() buttonType: HeyReachButtonType = 'primary';

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.applyStyles();
  }

  applyStyles(): void {
    const classes = [
      'flex',
      'px-5',
      'py-1',
      'h-11',
      'justify-center',
      'items-center',
      'rounded-lg',
      'text-nowrap',
      'opacity-100',
      'hover:opacity-80',
      'cursor-pointer',
      'gap-1.5',
    ];

    classes.forEach((x) => this._renderer.addClass(this._el.nativeElement, x));
    switch (this.buttonType) {
      case 'primary':
        this._el.nativeElement.style.backgroundColor = 'var(--primary-color)';
        this._renderer.addClass(this._el.nativeElement, 'text-white');
        break;
      case 'secondary':
        this._el.nativeElement.style.backgroundColor = 'var(--secondary-color)';
        break;
      case 'default':
        this._renderer.addClass(this._el.nativeElement, 'border-2');
        this._renderer.addClass(this._el.nativeElement, 'border-pale');
        this._renderer.addClass(this._el.nativeElement, 'text-secondary');
        break;
      case 'pale':
        this._renderer.addClass(this._el.nativeElement, 'bg-pale');
        break;
      case 'warn':
        this._el.nativeElement.style.backgroundColor = 'var(--warn-color)';
        this._renderer.addClass(this._el.nativeElement, 'text-white');
        break;
      case 'danger':
        this._el.nativeElement.style.backgroundColor = 'var(--danger-color)';
        this._renderer.addClass(this._el.nativeElement, 'text-white');
        break;
      case 'custom-border':
        this._renderer.addClass(this._el.nativeElement, 'border-2');
        this._renderer.addClass(this._el.nativeElement, 'border-secondary-muted');
        break;
      case 'icon':
        break;
    }
  }
}

export type HeyReachButtonType =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'pale'
  | 'warn'
  | 'danger'
  | 'custom-border'
  | 'icon';
