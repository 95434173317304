<div class="flex flex-col">
  <div class="relative">
    <div #wrapper class="show-more-wrapper" [class.expanded]="isExpanded">
      <ng-content></ng-content>
    </div>
    <div
      *ngIf="isContentOverflowing"
      class="show-more-wrapper-gradient"
      [class.expanded]="isExpanded"></div>
  </div>
  <button
    *ngIf="isContentOverflowing"
    class="mt-2 font-bold self-center"
    (click)="isExpanded = !isExpanded">
    {{ isExpanded ? 'Show less' : 'Show more' }}
  </button>
</div>
