<h3 class="text-lg font-semibold">Activate Infinite Login in 2 minutes</h3>
<p class="text-secondary font-medium">
  Using
  <a
    class="hover:underline"
    *ngIf="!isWhiteLabel"
    href="https://help.heyreach.io/en/articles/10665202-what-s-infinite-login"
    target="_blank">
    Infinite login
  </a>
  <span *ngIf="isWhiteLabel">Infinite login</span>
  protects your LinkedIn account from getting disconnected and pausing your campaigns. To be able to
  use it, you'll need to enable LinkedIn
  <a
    class="hover:underline"
    *ngIf="!isWhiteLabel"
    href="https://help.heyreach.io/en/articles/10665885-2fa-linkedin-guide?_gl=1*1ba26xz*_gcl_au*NTc4OTM5MTcyLjE3Mzg1MTgxMjguMTc5NzM5NDIxNS4xNzQxNjA4MDY0LjE3NDE2MDgwNjY"
    target="_blank">
    Two-Factor Authentication (2FA).
  </a>
  <span *ngIf="isWhiteLabel">Two-Factor Authentication (2FA).</span>
</p>
<p class="text-secondary font-medium">
  If your Linkedin account is already connected to {{ companyName }}, you'll need to
  <a
    class="hover:underline"
    *ngIf="!isWhiteLabel"
    href="https://help.heyreach.io/en/articles/10461395-how-to-disconnect-a-linkedin-account-from-heyreach#h_ff828db0e4"
    target="_blank">
    disconnect
  </a>
  <span *ngIf="isWhiteLabel">disconnect</span>

  and reconnect it to activate Infinite Login.
</p>
<div class="flex flex-col gap-y-4">
  <button class="w-full" heyReachButton (click)="onNo2FAClicked()" buttonType="default">
    No, I don't have 2FA
  </button>
  <button class="w-full" heyReachButton (click)="onYes2FAClicked()" buttonType="primary">
    Yes, I have 2FA
  </button>
</div>
