<div class="px-8 mx-4">
  <div class="mb-6">
    <span class="text-xl font-bold">{{ title }}</span>
    <p *ngIf="header && header !== ''" class="pt-10 pb-5 text-lg font-medium">{{ header }}</p>
  </div>

  <ng-container *ngIf="steps.length > 1; else singleStep">
    <mat-horizontal-stepper #horizontalStepper>
      <ng-template matStepperIcon="edit">
        <mat-icon class="text-gray-100 icon-size-4" [svgIcon]="'heroicons_solid:check'"></mat-icon>
      </ng-template>

      <mat-step *ngFor="let step of steps">
        <ng-template matStepLabel>{{ step.title }}</ng-template>
        <ng-container *ngTemplateOutlet="templ; context: { step: step }"></ng-container>
      </mat-step>
    </mat-horizontal-stepper>

    <div class="w-full flex justify-between mt-8 mb-10">
      <button
        heyReachButton
        buttonType="primary"
        type="button"
        [disabled]="horizontalStepper.selectedIndex == 0"
        (click)="goBack()">
        <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:arrow-left'"></mat-icon>
        Previous
      </button>
      <button
        heyReachButton
        buttonType="primary"
        type="button"
        [disabled]="horizontalStepper.selectedIndex == steps.length - 1"
        (click)="goForwards()">
        <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:arrow-right'"></mat-icon>
        Next
      </button>
    </div>
  </ng-container>

  <ng-template #singleStep>
    <ng-container *ngTemplateOutlet="templ; context: { step: steps[0] }"></ng-container>
  </ng-template>
</div>

<ng-template #templ let-step="step">
  <img
    class="image-max mt-4"
    *ngIf="step.image"
    [ngStyle]="{
      width: step.image.width ? step.image.width + 'px' : 'auto',
      height: step.image.height ? step.image.height + 'px' : 'auto'
    }"
    [src]="step.image.src"
    alt="" />
  <div *ngIf="step.embed" class="aspect-w-16 aspect-h-9">
    <iframe
      [src]="getSafeUrl(step.embed)"
      frameborder="0"
      controls="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>
  </div>
  <p class="mt-4 px-3" *ngIf="step.body" [innerHTML]="getSafeBody(step.body)"></p>
</ng-template>
