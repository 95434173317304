<div *ngIf="!showAll" class="overflow-hidden" #chipsContainer>
  <div
    class="inline py-0.5 px-2.5 whitespace-nowrap mr-1"
    *ngIf="chips.length > 0"
    [style.background-color]="chips[0].backgroundColor"
    [style.color]="chips[0].textColor"
    [style.fontWeight]="fontWeight"
    [ngClass]="chips[0].customClass">
    <ng-container *ngIf="!chips[0].urlLocation">
      <span *ngIf="chips[0].svgIcon">
        <mat-icon class="icon-size-3" [svgIcon]="chips[0].svgIcon"></mat-icon>
      </span>
      <span>{{ chips[0].displayText }}</span>
    </ng-container>
    <ng-container *ngIf="chips[0].urlLocation">
      <a (click)="openUrl(chips[0].urlLocation)" [style.color]="chips[0].textColor">
        <mat-icon
          *ngIf="chips[0].svgIcon"
          class="icon-size-3"
          [svgIcon]="chips[0].svgIcon"></mat-icon>
        Go to link
      </a>
    </ng-container>
  </div>

  <div
    customTemplateTooltip
    *ngIf="chips.length > 1"
    [tooltipTemplate]="chipsOnHover"
    [delay]="1000"
    class="inline m-1 py-0.5 px-1 text-black bg-gray-300 whitespace-nowrap z-50 cursor-pointer rounded-md">
    <span>+</span>
    <span>{{ chips.length - 1 }}</span>
  </div>

  <ng-template #chipsOnHover>
    <div class="bg-white px-4 py-2 shadow-xl">
      <div
        class="inline py-0.5 px-2.5 whitespace-nowrap mr-1"
        *ngFor="let chip of chips.slice(1)"
        [style.background-color]="chip.backgroundColor"
        [style.color]="chip.textColor"
        [ngClass]="chip.customClass">
        <ng-container *ngIf="!chip.urlLocation">
          <span *ngIf="chip.svgIcon">
            <mat-icon class="icon-size-3" [svgIcon]="chip.svgIcon"></mat-icon>
          </span>
          <span>{{ chip.displayText }}</span>
        </ng-container>
        <ng-container *ngIf="chip.urlLocation">
          <a (click)="openUrl(chip.urlLocation)" [style.color]="chip.textColor">
            <mat-icon *ngIf="chip.svgIcon" class="icon-size-3" [svgIcon]="chip.svgIcon"></mat-icon>
            Go to URL
          </a>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
<show-more *ngIf="showAll" [overflowHeight]="56">
  <div class="flex flex-wrap gap-3">
    <div
      class="inline py-0.5 px-2.5 whitespace-nowrap mr-1"
      *ngFor="let chip of chips"
      [style.background-color]="chip.backgroundColor"
      [style.color]="chip.textColor"
      [style.fontWeight]="fontWeight"
      [ngClass]="chip.customClass">
      <ng-container *ngIf="!chip.urlLocation">
        <span *ngIf="chip.svgIcon">
          <mat-icon class="icon-size-3" [svgIcon]="chip.svgIcon"></mat-icon>
        </span>
        <span>{{ chip.displayText }}</span>
      </ng-container>
      <ng-container *ngIf="chip.urlLocation">
        <a (click)="openUrl(chip.urlLocation)" [style.color]="chip.textColor">
          <mat-icon *ngIf="chip.svgIcon" class="icon-size-3" [svgIcon]="chip.svgIcon"></mat-icon>
          Go to link
        </a>
      </ng-container>
    </div>
  </div>
</show-more>
