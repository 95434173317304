import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
  LinkedInUserListDto,
  LinkedInUserListServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'list-select',
  templateUrl: './list-select.component.html',
  styleUrls: ['list-select.component.scss'],
})
export class ListSelectComponent implements OnInit, OnDestroy {
  pageNumber: number = 0;
  loadedLastPage: boolean = false;

  searchTerm: string = '';

  lists: LinkedInUserListDto[] = [];

  @Input() selectedList: LinkedInUserListDto;
  @Output() selectedListChange: EventEmitter<LinkedInUserListDto> =
    new EventEmitter<LinkedInUserListDto>();
  @Input() listType: number = undefined;

  searchTermUpdate = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();

  scrollPositionUpdate = new Subject<void>();
  fetchingFromApi: boolean = false;

  // Manually debouncing scroll time prevents multiple consequitve API requests which otherwise happen due to the msInfiniteScrolLibrary
  lastScrollTime = 0;

  constructor(private _linkedinUserListsService: LinkedInUserListServiceProxy) {}

  ngOnInit(): void {
    this.searchTermUpdate
      .pipe(
        debounceTime(AppConsts.searchInputDebounceTimeMillis),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((searchTerm) => {
        this.searchTerm = searchTerm;
        this.refresh();
      });

    this.scrollPositionUpdate
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !this.fetchingFromApi),
      )
      .subscribe(() => {
        this.pageNumber++;
        this.getLists();
      });

    this.refresh();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLists() {
    if (this.loadedLastPage) {
      return;
    }

    this.fetchingFromApi = true;

    this._linkedinUserListsService
      .getPaginatedLists(this.pageNumber, this.searchTerm, this.listType, undefined)
      .pipe(
        finalize(() => {
          this.fetchingFromApi = false;
        }),
      )
      .subscribe((res) => {
        this.lists = this.lists.concat(res.items);
        this.loadedLastPage = res.items.length == 0;
      });
  }

  onScrolled() {
    if (this.lastScrollTime && this.lastScrollTime + 500 >= Date.now()) {
      return;
    }

    this.lastScrollTime = Date.now();

    this.scrollPositionUpdate.next();
  }

  refresh(): void {
    this.lists = [];
    this.pageNumber = 0;
    this.loadedLastPage = false;
    this.getLists();
  }

  onSelectedListChange(): void {
    this.selectedListChange.emit(this.selectedList);
  }

  get filteredLists(): LinkedInUserListDto[] {
    return this.lists.filter(
      (x) => ((this.selectedList && this.selectedList.id != x.id) || !this.selectedList) && x.name,
    );
  }
}
