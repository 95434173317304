<div class="flex flex-col gap-y-4 overflow-hidden">
  <div class="data-table__controls" *ngIf="(filterEnabled || searchEnabled) && !error">
    <div class="functions-row">
      <button
        *ngIf="refreshEnabled"
        class="self-start s-mat-button-tiny bg-gray-200"
        heyReachButton
        buttonType="default"
        (click)="handleManualRefreshClick()">
        <mat-icon svgIcon="custom:refresh"></mat-icon>
      </button>
      <div>
        <button
          class="mr-2 bg-gray-200 s-mat-button-tiny"
          heyReachButton
          buttonType="default"
          *ngIf="filterEnabled"
          (click)="handleFilterButtonClick()">
          <mat-icon svgIcon="custom:filter"></mat-icon>
        </button>
      </div>
    </div>

    <div
      *ngIf="hasPersistentButtons || listCount > 0 || (areFiltersSelected$ | async)"
      class="flex flex-row w-full mt-6 px-7 bg-white">
      <app-dynamic-filter-row
        class="flex gap-x-4"
        [showButtons]="listCount > 0 || (areFiltersSelected$ | async)"
        [filters]="filters"
        [actions]="actions"
        [isDataLoading]="isDataLoading"
        [hasSelectedData]="selectedData.length > 0"
        [hasSelectedList]="showButton"
        [positionTableActionsAt]="positionTableActionsAt"
        (filtersChange)="handleFilterValuesChange($event)"></app-dynamic-filter-row>
      <app-column-picker
        *ngIf="columnPickerEnabled && (listCount > 0 || (areFiltersSelected$ | async))"
        [tableColumns]="tableColumns"
        (tableColumnsChange)="handleTableColumnsChange($event)"></app-column-picker>
    </div>
  </div>
  <div class="data-table__table p-0">
    <div
      class="data-table__table-wrapper border-t-2 border-secondary-muted"
      *ngIf="availableData?.length > 0 && !isDataLoading"
      #tableWrapper>
      <table
        class="min-w-full divide-y-2 divide-secondary-muted overflow-x-auto border-2 border-secondary-muted border-t-0 font-Inter"
        data-cy="data-table"
        *ngIf="tableColumns">
        <thead class="bg-gray-50 header-row" *ngIf="!error && availableData.length">
          <tr>
            <th
              *ngIf="selectEnabled && actions.length"
              class="pl-6 pr-2 w-0 py-3 text-left text-xs font-medium text-secondary">
              <div class="data-table__cell-inner">
                <div class="data-table__header">
                  <label class="inline-flex items-center">
                    <input
                      (change)="handleSelectChange($event)"
                      #selectAll
                      appCustomCheckbox
                      type="checkbox"
                      data-cy="data-table__select-all-checkbox" />
                  </label>
                </div>
              </div>
            </th>
            <ng-container *ngFor="let item of tableColumns; trackBy: trackByIndex; let i = index">
              <th
                class="py-1 text-left text-sm font-semibold text-secondary border-secondary-muted h-9"
                [ngClass]="[i === 0 ? 'first:px-0' : 'px-4', i == 0 ? 'pl-6' : 'pl-3']"
                [class.border-r-2]="!item.hideBorder"
                *ngIf="!item.hidden"
                [style.min-width]="item.minWidth"
                [style.width]="item.width"
                [attr.data-cy]="'data-table__' + item.id">
                <div class="flex flex-row">
                  <mat-icon
                    *ngIf="showIcon && item.showIcon"
                    class="icon-size-4 mr-2 text-primary"
                    [svgIcon]="'heroicons_solid:linkedin-box'"></mat-icon>
                  <div
                    class="data-table__cell-inner flex"
                    [style.min-width]="item.minWidth"
                    [style.width]="item.width">
                    <ng-container *ngIf="item.sort; else normalHeading">
                      <app-column-name
                        tabindex="0"
                        class="data-table__header"
                        [class.data-table__header--fix]="
                          columnFiltersEnabled && item.filterTemplateRef
                        "
                        [index]="i"
                        [active]="activeHeadingSortIndex === i"
                        [heading]="item.header"
                        [key]="item.field"
                        (sortByEvent)="sort($event)"></app-column-name>
                    </ng-container>
                    <mat-icon
                      *ngIf="item.infoText && item.infoText !== ''"
                      class="icon-size-4 ml-2"
                      [svgIcon]="'feather:info'"
                      [matTooltip]="item.infoText"></mat-icon>
                  </div>
                  <ng-template #normalHeading>
                    <div
                      class="data-table__header"
                      [class.data-table__header--fix]="
                        columnFiltersEnabled && item.filterTemplateRef
                      ">
                      {{ item.header }}
                    </div>
                  </ng-template>
                  <div
                    *ngIf="columnFiltersEnabled && item.filterTemplateRef"
                    [attr.data-cy]="'data-table__filter' + item.id"
                    class="data-table__filter-container">
                    <ng-container
                      [ngTemplateOutlet]="item?.filterTemplateRef() || null"></ng-container>
                  </div>
                </div>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody
          class="bg-white divide-y-2 divide-secondary-muted mt-2"
          *ngIf="availableData && availableData.length > 0">
          <tr
            tabindex="0"
            *ngFor="
              let dataItem of availableData;
              trackBy: trackByFunction ? trackByFunction : trackByIndex;
              let i = index;
              let count = count
            "
            class="px-6 py-4 whitespace-nowrap border-collapse border-2 border-secondary-muted hover:bg-light cursor-pointer h-14"
            data-cy="data-table__row"
            (keyup.enter)="rowClicked.next(dataItem)"
            (keyup.space)="rowClicked.next(dataItem)"
            (click)="rowClicked.next(dataItem)"
            [class.table__row--inactive]="activeRow && activeRow !== dataItem"
            [class.table__row--active]="activeRow === dataItem || selectedData.includes(dataItem)">
            <td *ngIf="selectEnabled && actions.length" class="px-6 data-table__checkbox-cell">
              <label class="inline-flex items-center" (click)="$event.stopPropagation()">
                <input
                  [checked]="!!selectedData.includes(dataItem)"
                  (change)="handleSelectChange($event, dataItem)"
                  appCustomCheckbox
                  type="checkbox"
                  data-cy="data-table__select-checkbox" />
              </label>
            </td>
            <ng-container *ngFor="let column of tableColumns; let g = index">
              <td
                class="border-secondary-muted"
                [class.border-r-2]="!column.hideBorder"
                *ngIf="!column.hidden"
                [ngClass]="[column?.cssCellClass?.(dataItem) || '', g == 0 ? 'pl-6' : 'pl-3']">
                <ng-container
                  [ngTemplateOutlet]="column?.templateRef?.() || defaultContentTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: dataItem,
                    column: column,
                    index: i
                  }"></ng-container>
              </td>
            </ng-container>
            <ng-template #defaultContentTemplate let-column="column">
              <table-row-field
                class="text-secondary text-base font-medium"
                [width]="column.width"
                [text]="$any(dataItem)[toTableColumn(column).field]"></table-row-field>
            </ng-template>
          </tr>
        </tbody>
      </table>
      <paginator
        *ngIf="pagination"
        [total]="listCount"
        [resultsPerPage]="pagination.resultsPerPage"
        [pageNumber]="pagination.pageNumber"
        (pageNumberChange)="pageChanged($event)"></paginator>
    </div>
    <div
      class="data-table__overlay"
      data-cy="data-table__overlay"
      *ngIf="availableData?.length === 0 || error || isDataLoading">
      <div class="data-table__overlay-message" data-cy="data-table__overlay-message">
        <div
          *ngIf="showNoData && !error && !isDataLoading && !(areFiltersSelected$ | async)"
          class="data-table__no-data"
          data-cy="data-table__no-data">
          <div class="text-secondary text-center flex flex-col mt-3 mb-3">
            <div class="text-secondary text-center flex flex-col mt-3 mb-3">
              <img class="m-auto w-50 my-10" [src]="notDataFoundImgLink" />
              <p class="font-semibold p-0 m-0" [innerHTML]="noDataMessage.message"></p>
              <button
                *ngIf="noDataMessage.button"
                heyReachButton
                class="gap-x-2 mt-4 m-auto w-auto"
                [buttonType]="noDataMessage.button.buttonType">
                <svg-icon [iconPath]="noDataMessage.button.icon"></svg-icon>
                {{ noDataMessage.button.text }}
              </button>
            </div>
          </div>
        </div>
        <h1
          *ngIf="!isDataLoading && (areFiltersSelected$ | async)"
          class="text-xl text-center text-dark font-semibold py-6 m-0">
          No results matched your search criteria.
        </h1>
        <div *ngIf="error" class="data-table__error" data-cy="data-table__error">
          <app-message-box [title]="error" type="danger"></app-message-box>
        </div>
        <div
          *ngIf="isDataLoading"
          class="data-table__loading w-full text-center mx-auto pt-2.5"
          data-cy="data-table__loading">
          <progress-spinner [diameter]="10" color="primary" class="mx-auto"></progress-spinner>
          <app-message-box title="Loading data"></app-message-box>
        </div>
      </div>
    </div>
  </div>
</div>
