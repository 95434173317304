import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
})
export class ShowMoreComponent implements AfterViewInit, AfterContentChecked {
  @ViewChild('wrapper') wrapper!: ElementRef<HTMLElement>;
  @Input() overflowHeight: number = 100;

  isExpanded: boolean = false;
  isContentOverflowing: boolean = false;
  currentScrollHeight: number = -1;

  constructor(private _cdr: ChangeDetectorRef) {}

  private get wrapperScrollHeight(): number {
    const wrapperEl = this.wrapper?.nativeElement;
    return wrapperEl?.scrollHeight ?? 0;
  }

  private get wrapperClientHeight(): number {
    const wrapperEl = this.wrapper?.nativeElement;
    return wrapperEl?.clientHeight ?? 0;
  }

  private handleOverflow(): void {
    this.isContentOverflowing = this.wrapperScrollHeight > this.wrapperClientHeight;
    this._cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.handleOverflow();
  }

  ngAfterContentChecked() {
    if (this.currentScrollHeight !== this.wrapperScrollHeight) {
      this.currentScrollHeight = this.wrapperScrollHeight;
      this.handleOverflow();
    }
  }

  @HostBinding('style.--overflow-height')
  public get overflowHeightStyle(): string {
    return this.overflowHeight + 'px';
  }

  @HostBinding('style.--actual-height')
  public get actualHeightStyle(): string {
    return this.wrapperScrollHeight + 'px';
  }
}
