<div class="flex flex-col gap-y-4">
  <div class="flex flex-col gap-y-1">
    <label class="text-secondary flex flex-col gap-y-1">Your LinkedIn Email address</label>
    <input
      type="email"
      class="rounded-md border-2 border-secondary-muted py-2 px-3 w-full"
      placeholder="LinkedIn email"
      [ngModel]="username"
      (ngModelChange)="onUsernameChange($event)"
      [disabled]="isDisabled" />
  </div>

  <div class="flex flex-col gap-y-1">
    <label class="text-secondary flex flex-col gap-y-1">Your LinkedIn password</label>
    <password-visibility
      [placeholder]="'LinkedIn password'"
      [inputPasswordChange]="password"
      [disableAutofill]="true"
      (passwordChange)="onPasswordChange($event)"></password-visibility>
  </div>

  <div class="flex flex-col gap-y-1">
    <label class="text-secondary flex flex-col gap-y-1">
      Enter your 2FA secret key
      <p class="text-third-background-primary-text font-light text-sm">
        Your 2FA secret key is not the code from the Authenticator app.
      </p>
      <input
        type="text"
        placeholder="2FA secret key"
        class="rounded-md border-2 border-secondary-muted py-2 px-3 w-full"
        [ngModel]="secretKey"
        (ngModelChange)="onSecretKeyChange($event)"
        [disabled]="isDisabled" />
    </label>
    <p *ngIf="secretKeyHasError" class="text-error">
      It seems you're inputting the wrong code. Keep in mind your 2FA secret key is not the code you
      get from the Authenticator app.
      <span *ngIf="!isWhiteLabel">
        In case you can't find your key,
        <a
          target="_blank"
          class="font-semibold text-error underline"
          href="https://help.heyreach.io/en/articles/10665940-step-by-step-guide-where-to-find-your-2fa-secret-key?_gl=1*1r7u3qk*_gcl_au*NTc4OTM5MTcyLjE3Mzg1MTgxMjguMTc5NzM5NDIxNS4xNzQxNjA4MDY0LjE3NDE2MDgwNjY">
          click here.
        </a>
      </span>
    </p>
  </div>
  <a
    *ngIf="!isWhiteLabel"
    href="https://help.heyreach.io/en/articles/10665940-step-by-step-guide-where-to-find-your-2fa-secret-key?_gl=1*1r7u3qk*_gcl_au*NTc4OTM5MTcyLjE3Mzg1MTgxMjguMTc5NzM5NDIxNS4xNzQxNjA4MDY0LjE3NDE2MDgwNjY"
    target="_blank"
    class="flex justify-end hover:underline">
    Can't find your 2FA secret key?
  </a>

  <div class="flex flex-col">
    <linkedin-inbox-configuration
      [label]="inboxScrapeConfigurationLabel"
      [inboxConfigurations]="inboxConfigurations"
      [inboxPrivacyConfiguration]="inboxScrapeConfiguration"
      (onSelectedInboxOptionChange)="
        onInboxConfigurationChange($event)
      "></linkedin-inbox-configuration>
  </div>

  <app-connect-account-proxy *ngIf="isUsingCustomProxy"></app-connect-account-proxy>

  <div class="flex flex-col mt-2">
    <button
      buttonType="primary"
      heyReachButton
      class="w-full"
      [disabled]="isFormDisabled || isDisabled"
      (click)="onConnectAccount()">
      <div class="flex items-center">
        <mat-icon svgIcon="custom:linkedin" class="icon-size-5"></mat-icon>
        <span class="ml-2">Connect account</span>
      </div>
    </button>
  </div>

  <div class="flex flex-col">
    <button
      buttonType="default"
      heyReachButton
      class="w-full"
      [disabled]="isDisabled"
      (click)="onToggleProxy()">
      <div class="flex items-center">
        <span class="ml-2" *ngIf="!isUsingCustomProxy">Add your own proxy</span>
        <span class="ml-2" *ngIf="isUsingCustomProxy">
          Switch back to {{ companyName }} proxies
        </span>
      </div>
    </button>
  </div>
</div>
