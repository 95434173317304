<div class="modal-footer justify-content-between border-none">
  <button
    heyReachButton
    buttonType="default"
    type="button"
    [disabled]="cancelDisabled"
    (click)="onCancelClick.emit()">
    {{ cancelLabel }}
  </button>
  <button type="submit" heyReachButton buttonType="primary" [disabled]="saveDisabled">
    {{ saveLabel }}
  </button>
</div>
