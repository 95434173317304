<span
  *ngIf="
    account &&
    (account.isSearchLocked ||
      account.connectionRequestCooldown ||
      account.isInMailLocked ||
      account.hasProxyIssue)
  ">
  <img
    *ngIf="!account.hasProxyIssue"
    [matTooltip]="getTooltipText()"
    class="icon-size-4"
    style="position: relative; top: 1px"
    src="assets/icons/accounts-icons/snowflake.svg"
    alt="" />
  <mat-icon
    *ngIf="account.hasProxyIssue"
    [matTooltip]="getTooltipText()"
    class="icon-size-4 text-danger"
    style="margin-top: 1px"
    [svgIcon]="'warning_amber'"></mat-icon>
</span>
