import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInterfaceService } from '@shared/user-interface/user-interface.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from '@shared/consts/app-routes.consts';
import { WhiteLabelService } from '@app/white-label.service';
import { AppSessionService } from '@shared/session/app-session.service';

@Component({
  selector: 'settings-sidebar-menu',
  templateUrl: './settings-sidebar-menu.component.html',
})
export class SettingSideBarMenuComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  calculatedHeightPx: number = 0;
  hideBilling =
    this.whiteLabelService.notUs || this.appSession.isAdminTenant || !this.appSession.isAdminUser;
  constructor(
    private _uiService: UserInterfaceService,
    private route: ActivatedRoute,
    private whiteLabelService: WhiteLabelService,
    private appSession: AppSessionService,
  ) {}

  ngOnInit(): void {
    timer(0, 1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.calculateHeight();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSideBarMenuUrls(routeName: string): string {
    return `/app/${AppRoutes[routeName] || ''}`;
  }

  calculateHeight() {
    this.calculatedHeightPx = this._uiService.calculateHeight();
  }

  isActiveLink(activeLink: string): boolean {
    return this.route.snapshot.routeConfig.path === activeLink;
  }
}
