import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';
import { DisplayedScreenInLogin } from '@app/linkedin-accounts/linked-in-accounts.service';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  imports: [CommonModule, HeyReachButtonDirective],
  selector: 'app-enable-infinite-login-prompt',
  templateUrl: 'enable-infinite-login-prompt.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'flex flex-col gap-y-6',
  },
})
export class EnableInfiniteLoginPromptComponent {
  @Output() screenToBeDisplayed: EventEmitter<DisplayedScreenInLogin> =
    new EventEmitter<DisplayedScreenInLogin>();

  @Output() userHas2Fa: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected isWhiteLabel: boolean = this._whiteLabelService.notUs;
  protected companyName: string = this._whiteLabelService.companyName;

  constructor(private _whiteLabelService: WhiteLabelService) {}

  onNo2FAClicked(): void {
    this.userHas2Fa.emit(false);
    this.screenToBeDisplayed.emit('2fa-tutorial');
  }

  onYes2FAClicked(): void {
    this.userHas2Fa.emit(true);
    this.screenToBeDisplayed.emit('2fa-confirmation');
  }
}
