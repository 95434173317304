import { Pipe, PipeTransform } from '@angular/core';
import { AccountActionType } from '@shared/service-proxies/service-proxies';

@Pipe({
  name: 'leadActivityActionIsFromSender',
})
export class LeadActivityActionIsFromSenderPipe implements PipeTransform {
  transform(action: AccountActionType): boolean {
    switch (action) {
      case AccountActionType._0:
      case AccountActionType._3:
      case AccountActionType._4:
      case AccountActionType._6:
      case AccountActionType._7:
      case AccountActionType._9:
      case AccountActionType._10:
      case AccountActionType._11:
      case AccountActionType._12:
      case AccountActionType._13:
      case AccountActionType._14:
        return true;
      default:
        return false;
    }
  }
}
