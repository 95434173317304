export const searchTextMap = (companyName) => ({
  0: {
    title: 'LinkedIn Search Bar',
    subtitle: 'Import from LinkedIn search bar',
    description:
      'Find and extract people from LinkedIn. This is basically the same as using the search bar in LinkedIn. Compatible with free LinkedIn accounts',
    logo: 'assets/icons/search-icons/color/peoplesearch.svg',
    image: 'assets/images/searches/LinkedIn-search-bar-prospects.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-2009296451',
  },
  1: {
    title: 'LinkedIn Search Bar',
    description:
      "Start a new search to find unlimited, unique leads on LinkedIn! Search and filter by position, company, industry, location and much more. Use an upgraded multi-account version of LinkedIn's people search to find your leads.",
    subtitle: 'Import from LinkedIn search bar',
    logo: 'assets/icons/search-icons/color/peoplesearch.svg',
    image: 'assets/images/searches/LinkedIn-search-bar-prospects.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-2009296451',
  },
  2: {
    title: 'Sales Navigator (Leads)',
    description: `Export leads from Sales Navigator directly into ${companyName}. You'll need at least one LinkedIn account with Sales Navigator subscription`,
    logo: 'assets/icons/search-icons/color/salesnavigatorsearch.svg',
    image: 'assets/images/searches/Sales-navigator-leads.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h985484385',
  },
  3: {
    title: 'LinkedIn Group (Members)',
    description: `Join a LinkedIn group, and extract all members into ${companyName} with a click. Compatible with free LinkedIn accounts`,
    logo: 'assets/icons/search-icons/color/groupmemberssearch.svg',
    image: 'assets/images/searches/LinkedIn-group.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-777370131',
  },
  4: {
    title: 'LinkedIn Event (Attendees)',
    description: `Extract all people who are attending a LinkedIn event into ${companyName}, and reach out to all of them. Compatible with free LinkedIn accounts`,
    logo: 'assets/icons/search-icons/color/eventatendeessearch.svg',
    image: 'assets/images/searches/LinkedIn-event.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-1685399083',
  },
  5: {
    title: 'LinkedIn Post (Reactors)',
    description: `Extract all people who reacted on a LinkedIn post directly into ${companyName}. Compatible with free LinkedIn accounts`,
    logo: 'assets/icons/search-icons/color/postengagementssearch.svg',
    image: 'assets/images/searches/Linkedin-post-reactions.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-1926157434',
  },
  6: {
    title: 'Sales Navigator (Accounts)',
    description: `Export accounts (companies) from Sales Navigator directly into ${companyName}. You'll need at least one LinkedIn account with Sales Navigator subscription`,
    logo: 'assets/icons/search-icons/color/salesnavigatorcompanysearch.svg',
    image: 'assets/images/searches/Sales-navigator-accounts.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-2096185845',
  },
  9: {
    title: 'LinkedIn search bar (Companies)',
    description:
      'Find and extract companies form LinkedIn. The extracted companies can be used to extract employees or decision makers from them. Compatible with free LinkedIn accounts',
    logo: 'assets/icons/search-icons/color/companysearch.svg',
    image: 'assets/images/searches/LinkedIn-search bar-companies.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-639914477',
  },
  10: {
    title: 'LinkedIn Recruiter',
    description: `Export potential candidates from Recruiter directly into ${companyName}. You'll need at least one LinkedIn account with Recruiter Lite or Corporate subscription`,
    logo: 'assets/icons/search-icons/color/recruiter-icon.svg',
    image: 'assets/images/searches/LinkedIn-Recruiter.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods',
  },
  11: {
    title: 'Import from CSV',
    description: `Import a list of people through a CSV & assign custom variables.  You'll need to have their LinkedIn profile URLs at least, so we can load them into ${companyName}`,
    logo: 'assets/icons/search-icons/color/import-csv-active.svg',
    image: 'assets/images/searches/Import-as-csv.png',
    knowledgeBaseLink: 'https://help.heyreach.io/en/articles/27-import-methods#h-177830133',
  },
});
