import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { InputStyleDirective } from '@shared/directives/input.directive';
import { FormsModule } from '@node_modules/@angular/forms';
import { NgClass, NgIf, NgStyle } from '@node_modules/@angular/common';

@Component({
  selector: 'password-visibility',
  templateUrl: './password-visibility.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./password-visibility.component.scss'],
  standalone: true,
  imports: [MaterialModule, InputStyleDirective, FormsModule, NgClass, NgIf, NgStyle],
})
export class PasswordVisibilityComponent {
  @Input() showToggleVisibilityButton = true;
  @Input() disableAutofill = false;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = false;
  @Output() passwordChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() inputPasswordChange: string = '';
  isPasswordVisible: boolean = false;
  @ViewChild('randomFieldToConfuseBrowsers') passwordField: HTMLInputElement;

  emitPasswordChange() {
    this.passwordChange.emit(this.inputPasswordChange);
    if (this.inputPasswordChange.length == 0) {
      this.isPasswordVisible = false;
    }
  }
}
