import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';

@Component({
  selector: 'error-snackbar',
  templateUrl: 'error-snackbar.component.html',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarModule, HeyReachButtonDirective],
})
export class ErrorSnackbarComponent {
  public message: string = '';

  snackBarRef = inject(MatSnackBarRef);
}
