import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'shortTimeAgo' })
export class ShortTimeAgoPipe implements PipeTransform {
  transform(value: Date | moment.Moment): string {
    const duration = moment.duration(moment().diff(value));
    const hours = Math.ceil(duration.asHours());
    const days = Math.floor(duration.asDays());
    const weeks = Math.floor(duration.asWeeks());
    const months = Math.floor(duration.asMonths());
    const years = Math.floor(duration.asYears());

    if (years > 0) {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    } else if (months > 0) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (days > 0) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }
  }
}
