<dialog-container (closeClick)="onCancel()" [closeDisabled]="isLoading">
  <dialog-header [title]="data.title"></dialog-header>
  <div class="form-horizontal" autocomplete="off">
    <div class="w-100">
      <mat-form-field class="w-100">
        <mat-label>{{ data.inputLabel }}</mat-label>
        <input [disabled]="isLoading" type="text" matInput [(ngModel)]="input" required />
      </mat-form-field>
    </div>

    <button
      class="fuse-mat-button-large w-full mt-6"
      heyReachButton
      buttonType="primary"
      [disabled]="!isInputValid || isLoading"
      (click)="onConfirm()">
      <span [loading]="isLoading">Confirm</span>
    </button>
  </div>
</dialog-container>
