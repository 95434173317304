import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DisplayedScreenInLogin } from '@app/linkedin-accounts/linked-in-accounts.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  imports: [CommonModule, MatIconModule],
  selector: 'app-account-connection-method-selection',
  templateUrl: 'account-connection-method-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'flex flex-col',
  },
})
export class AccountConnectionMethodSelectionComponent {
  @Output() screenToBeDisplayed: EventEmitter<DisplayedScreenInLogin> =
    new EventEmitter<DisplayedScreenInLogin>();

  protected isWhiteLabel: boolean = this.whiteLabelService.notUs;

  constructor(private readonly whiteLabelService: WhiteLabelService) {}
}
