<span
  *ngIf="
    account &&
    (account.isSearchLocked || account.connectionRequestCooldown || account.isInMailLocked)
  ">
  <img
    [matTooltip]="getTooltipText()"
    class="icon-size-4"
    style="position: relative; top: 1px"
    src="assets/icons/accounts-icons/snowflake.svg"
    alt="" />
</span>
