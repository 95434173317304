<label
  class="w-full rounded-md border-2 relative p-2 px-3 flex justify-between bg-white"
  [class.border-warn]="isValid"
  [class.border-secondary-muted]="!isValid">
  <input
    [autocomplete]="disableAutofill ? 'one-time-code' : 'on'"
    class="w-11/12"
    standardInput
    id="we-do-not-specify-so-that-the-browser-does-not-recognize-it-and-autofill-it"
    #randomFieldToConfuseBrowsers
    name="we-do-not-specify-so-that-the-browser-does-not-recognize-it-and-autofill-it"
    [type]="disableAutofill || isPasswordVisible ? 'text' : 'password'"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="inputPasswordChange"
    [ngStyle]="{
      fontFamily:
        this.disableAutofill &&
        !this.isPasswordVisible &&
        this.inputPasswordChange &&
        this.inputPasswordChange.length != 0
          ? 'password-font'
          : 'inherit'
    }"
    (input)="emitPasswordChange()"
    spellcheck="false" />
  <button
    class="right-3.5 bottom-0 absolute top-1.5"
    *ngIf="showToggleVisibilityButton && inputPasswordChange?.length > 0"
    type="button"
    (click)="this.isPasswordVisible = !this.isPasswordVisible">
    <mat-icon
      class="icon-size-5"
      [svgIcon]="isPasswordVisible ? 'heroicons_solid:eye' : 'heroicons_solid:eye-off'"></mat-icon>
  </button>
</label>
