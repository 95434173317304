<div class="flex flex-col gap-y-4">
  <mat-expansion-panel class="border-2 border-secondary-muted mb-0 shadow-none" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="text-third-background-primary-text">
        Did you use Authenticator app to enable 2FA?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      class="flex flex-col gap-y-4 text-secondary font-medium border-t-2 border-secondary-muted pt-4">
      <p>
        To be able to use Infinite login, you'll have to use app-based authentication code, and not
        via phone (SMS).
      </p>
      <p>
        <strong class="text-third-background-primary-text">Didn't use Authenticator app?</strong>
        You'll need to disable 2FA and turn it back on to get a new key.
        <span *ngIf="!isWhiteLabel">
          Here's an
          <a
            class="hover:underline"
            href="https://help.heyreach.io/en/articles/10665940-step-by-step-guide-where-to-find-your-2fa-secret-key?_gl=1*bunqjt*_gcl_au*NTc4OTM5MTcyLjE3Mzg1MTgxMjguMTc5NzM5NDIxNS4xNzQxNjA4MDY0LjE3NDE2MDgwNjY.#h_528addc153"
            target="_blank">
            easy-to-follow guide
          </a>
        </span>
      </p>
      <label class="flex flex-row gap-x-2 items-center text-secondary font-medium">
        <input type="checkbox" [(ngModel)]="userConfirmsTheyUsedAuthenticatorApp" />
        Yes, I confirm I used the Authenticator app
      </label>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="border-2 border-secondary-muted mb-0 shadow-none" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="text-third-background-primary-text">
        Do you have your 2FA secret key?
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      class="flex flex-col gap-y-2 border-t-2 border-secondary-muted pt-4 text-secondary font-medium">
      <img src="assets/images/login/2fa-tutorial.png" />
      <p>
        2FA secret key is a unique code you get from LinkedIn once you enable your 2FA. Keep in mind
        that you can't access it again.
      </p>
      <p>
        <strong class="text-third-background-primary-text">Lost your 2FA secret key?</strong>
        You'll need to disable 2FA and turn it back on to get a new key.
        <span *ngIf="!isWhiteLabel">
          Here's an
          <a
            class="hover:underline"
            href="https://help.heyreach.io/en/articles/10665940-step-by-step-guide-where-to-find-your-2fa-secret-key"
            target="_blank">
            easy-to-follow guide
          </a>
        </span>
      </p>
      <label class="flex flex-row gap-x-2 items-center text-secondary font-medium">
        <input type="checkbox" [(ngModel)]="userConfirmsTheyHaveTheir2faKey" />
        Yes, I have my 2FA secret key
      </label>
    </div>
  </mat-expansion-panel>
</div>
<div class="px-2 w-full">
  <button
    class="w-full"
    heyReachButton
    (click)="screenToBeDisplayed.emit('2fa-login')"
    buttonType="primary"
    [disabled]="!userConfirmsTheyHaveTheir2faKey || !userConfirmsTheyUsedAuthenticatorApp">
    Next
  </button>
</div>
