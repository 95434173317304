import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { getTextWidth } from 'get-text-width';
import { ChipWithOverflow } from './chips-with-overflow.model';

@Component({
  selector: 'chips-overflow',
  templateUrl: './chips-overflow.component.html',
})
export class ChipsOverflowComponent implements OnChanges {
  private _chips: ChipWithOverflow[] = [];
  get chips(): ChipWithOverflow[] {
    return this._chips;
  }
  @Input()
  public set chips(value: ChipWithOverflow[]) {
    const newChips = this.ensureUniqueChips(value);
    if (!_.isEqual(newChips, this._chips)) {
      this._chips = newChips;
      this.chipsNumberChanged.emit(this.chips.length);
    }
  }

  @Input() fontWeight: string = 'semibold';
  @Input() widthPx: number = 0;
  @Input() shownChipsCount: number = 3;
  @Input() showAll: boolean = false;
  @ViewChild('chipsContainer') chipsContainerElement: ElementRef;
  @Output() chipsNumberChanged: EventEmitter<number> = new EventEmitter<number>();

  get chipsDisplayedByDefault(): ChipWithOverflow[] {
    return this.chips.slice(0, this.shownChipsCount);
  }
  get chipsDisplayedOnHover(): ChipWithOverflow[] {
    return this.chips.slice(this.shownChipsCount);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chips && this.chipsContainerElement) {
      this.widthPx = this.chipsContainerElement.nativeElement.offsetWidth;
      this.shownChipsCount = this.calculateChipsSplittingIndex(0, this.widthPx);
    }
  }

  calculateChipsSplittingIndex(
    currentWidth: number,
    containerWidth: number,
    currentIndex: number = 0,
  ): number {
    if (currentIndex >= this.chips.length) return currentIndex;

    let calculatedLength = this.chips[currentIndex].urlLocation
      ? getTextWidth('Go to link')
      : this.chips[currentIndex].displayText.length;

    if (this.chips[currentIndex].svgIcon) {
      calculatedLength += 30;
    }

    calculatedLength += getTextWidth(this.chips[currentIndex].displayText) * 2.5;
    currentWidth += calculatedLength;

    if (currentWidth > containerWidth) return currentIndex;

    return this.calculateChipsSplittingIndex(currentWidth, containerWidth, currentIndex + 1);
  }

  openUrl(location: string): void {
    window.open(location, '_blank');
  }

  private ensureUniqueChips(chips: ChipWithOverflow[]): ChipWithOverflow[] {
    const uniqueChips = _.uniqBy(chips, 'displayText');
    return uniqueChips;
  }
}
