import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { TokenService, LogService, UtilsService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import {
  AuthenticateModel,
  AuthenticateResultModel,
  TokenAuthServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { InterceptorDisabler } from '@shared/interceptors/disable-interceptor';
import { startWithTap } from '@shared/pipes/start-with-tap.pipe';
import { Observable, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import Gleap from 'gleap';

@Injectable()
export class AppAuthService {
  authenticateModel: AuthenticateModel;
  authenticateResult: AuthenticateResultModel;
  rememberMe: boolean;
  constructor(
    private _tokenAuthService: TokenAuthServiceProxy,
    private _router: Router,
    private _utilsService: UtilsService,
    private _tokenService: TokenService,
    private _logService: LogService,
    private _interceptorDisabler: InterceptorDisabler,
    private _dialog: MatDialog,
  ) {
    this.clear();
  }

  logout(reload?: boolean): void {
    abp.auth.clearToken();
    abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);

    // if (Gleap && Gleap.isUserIdentified()) {
    //   Gleap.clearIdentity();
    // }

    if (reload !== false) {
      // location.href = AppConsts.appBaseUrl;
      window.location.reload();
    }
  }

  authenticate(finallyCallback?: (message: string) => void): void {
    finallyCallback = finallyCallback || (() => {});
    this.authenticateModel.gaid = abp.utils.getCookieValue('_ga');
    this._tokenAuthService
      .authenticate(this.authenticateModel)
      .pipe(
        startWithTap(() => {
          this._interceptorDisabler.disable();
        }),
        catchError((error): Observable<never> => {
          finallyCallback(JSON.parse(error.response)?.error?.details || 'Error');

          return throwError(error);
        }),
      )
      .subscribe((result: AuthenticateResultModel) => {
        this.processAuthenticateResult(result);
        finallyCallback('');
      });
  }

  private processAuthenticateResult(authenticateResult: AuthenticateResultModel) {
    this.authenticateResult = authenticateResult;

    if (authenticateResult.accessToken) {
      // Successfully logged in
      this.login(
        authenticateResult.accessToken,
        authenticateResult.encryptedAccessToken,
        authenticateResult.expireInSeconds,
        this.rememberMe,
      );
    } else {
      // Unexpected result!

      this._logService.warn('Unexpected authenticateResult!');
      this._router.navigate(['account/login'], { queryParamsHandling: 'preserve' });
    }
  }

  private login(
    accessToken: string,
    encryptedAccessToken: string,
    expireInSeconds: number,
    rememberMe?: boolean,
  ): void {
    const tokenExpireDate = rememberMe
      ? new Date(new Date().getTime() + 1000 * expireInSeconds)
      : undefined;

    this._tokenService.setToken(accessToken, tokenExpireDate);

    this._utilsService.setCookieValue(
      AppConsts.authorization.encryptedAuthTokenName,
      encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
    );

    let initialUrl = UrlHelper.initialUrl;
    if (
      initialUrl.indexOf('/login') > 0 ||
      initialUrl.indexOf('/join') > 0 ||
      initialUrl.indexOf('/register') > 0 ||
      initialUrl.indexOf('/confirm-email') > 0 ||
      initialUrl.indexOf('/forgot') > 0 ||
      initialUrl.indexOf('/resend') > 0 ||
      initialUrl.indexOf('/reset-password') > 0
    ) {
      initialUrl = '/';
    }

    location.href = initialUrl;
  }

  private clear(): void {
    this.authenticateModel = new AuthenticateModel();
    this.authenticateModel.rememberClient = false;
    this.authenticateResult = null;
    this.rememberMe = false;
  }
}
