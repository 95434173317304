import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTagDialogComponent } from '../create-tag-dialog/create-tag-dialog.component';
import { ProfileTagService } from '@shared/inbox-service/profile-tag.service';
import {
  LinkedInProfileTagDto,
  LinkedInUserProfileDto,
  LnkLinkedInProfileTagDto,
} from '@shared/service-proxies/service-proxies';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { InboxService } from '@shared/inbox-service/inbox.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchFieldComponent } from '@app/integrations/search-field/search-field.component';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog.component';
import { ConfirmationDialogData } from '@shared/components/dialogs/import-csv-dialog/import-csv-dialog.component';

@Component({
  selector: 'tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TagsListComponent implements OnInit, OnDestroy {
  @Input() selectedAll: boolean = false;
  @Input() isSingleTagView: boolean = true;
  @Input() selectedProfiles: LinkedInUserProfileDto[] = [];
  @Input() buttonWithText: boolean = false;
  @Output() onAssignTag: EventEmitter<LnkLinkedInProfileTagDto[]> = new EventEmitter<
    LnkLinkedInProfileTagDto[]
  >();
  @Output() onUnassignTag: EventEmitter<number> = new EventEmitter<number>();
  @Output() onAssignTagIds: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateTagChange: EventEmitter<LinkedInProfileTagDto> =
    new EventEmitter<LinkedInProfileTagDto>();
  @Output() onTagDeleted: EventEmitter<number> = new EventEmitter<number>();

  chipsNumber: number = 0;

  tagUpdatesInProgress: number[] = [];
  assignedTagsToAllChatRooms: number[] = [];
  searchTerm: string = '';
  tags: LinkedInProfileTagDto[] = [];
  filteredTags: LinkedInProfileTagDto[] = [];
  loading: boolean = true;
  @ViewChild(SearchFieldComponent, { static: false }) searchFieldComponent!: SearchFieldComponent;
  @ViewChild('toggleLabelMenu') toggleLabelMenu!: MatMenuTrigger;
  private readonly approximatedTruncateLength = 20;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _inboxService: InboxService,
    public _tagService: ProfileTagService,
    private _dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private _elementRef: ElementRef,
  ) {}

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    if (
      this.toggleLabelMenu &&
      this.toggleLabelMenu.menuOpen &&
      !this._elementRef.nativeElement.contains(event.target)
    ) {
      this.toggleLabelMenu.closeMenu();
    }
  }

  ngOnInit(): void {
    this._tagService.tags$.pipe(takeUntil(this.destroy$)).subscribe((tags) => {
      this.tags = tags;
      this.filteredTags = [...this.tags];
      this.filterTags();
      this.cdr.detectChanges();
    });

    this._tagService.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      this.loading = loading;
    });

    if (this.searchFieldComponent) {
      this.searchFieldComponent.filter
        .pipe(
          debounceTime(AppConsts.searchInputDebounceTimeMillis),
          distinctUntilChanged(),
          takeUntil(this.destroy$),
        )
        .subscribe((searchTerm) => {
          this.searchTerm = searchTerm.trim().toLowerCase();
          this.filterTags();
          this.focusSearchInput();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  createTag(): void {
    this._dialog.open(CreateTagDialogComponent, {
      minWidth: '450px',
    });
  }

  deleteTag(tagId: number) {
    this.openDialog({
      message: 'Are you sure you want to delete this tag?',
      confirmButtonColor: 'red',
    } as ConfirmationDialogData).subscribe((result: boolean) => {
      if (result) {
        this._tagService.deleteTag(tagId);
        this.onTagDeleted.emit(tagId);
        if (!this.buttonWithText) {
          this.onUnassignTag.emit(tagId);
        }
        this.selectedProfiles.forEach((profile) => {
          profile.tagLinks = profile.tagLinks.filter((tag) => tag.profileTagId !== tagId);
        });
        this.filterTags();
      }
    });
  }

  openDialog(data: ConfirmationDialogData): Observable<boolean> {
    return this._dialog
      .open(ConfirmationDialogComponent, {
        data: data,
      })
      .afterClosed();
  }

  editTag(tag: LinkedInProfileTagDto) {
    this._dialog
      .open(CreateTagDialogComponent, {
        minWidth: '450px',
        data: tag,
      })
      .afterClosed()
      .subscribe(async (res: LinkedInProfileTagDto) => {
        if (res) {
          const existingTagId = this.tags.findIndex((x) => x.id === res.id);
          if (existingTagId !== -1) {
            this.tags[existingTagId] = res;
            this.cdr.detectChanges();
          }
          this.onUpdateTagChange.emit(res);
          this._inboxService.updateTag(res);
        }
      });
  }

  async toggleTag(tagId: number) {
    this.tagUpdatesInProgress = [...this.tagUpdatesInProgress, tagId];
    let tagLinks: LnkLinkedInProfileTagDto[] = [];
    const tagFound = this.chatHasTag(tagId);
    const selectedProfileIds = this.selectedProfiles.map((x) => x.id);

    // NOTE: this only emits the values from the tags ideally it should be refactored so that all code uses this
    // We emit the values we should add or remove
    // and higher purpose function or service is going to handle the logic in a specific manner based on the context
    if (this.buttonWithText) {
      if (tagFound) {
        this.onUnassignTag.emit(tagId);
      } else {
        this.onAssignTagIds.emit(tagId);
      }
      this.tagUpdatesInProgress = [...this.tagUpdatesInProgress.filter((x) => x !== tagId)];
      return;
    }
    const getAllChatRoomsCorrespondentProfileIds = this._inboxService.getCurrentChatRooms();

    try {
      if (tagFound) {
        if (this.selectedAll) {
          const excludeProfileIds = getAllChatRoomsCorrespondentProfileIds.filter(
            (item) => !selectedProfileIds.includes(item.correspondentMemberId),
          );
          await this._tagService.unAssignTagsByFilters(tagId, excludeProfileIds);
          const index = this.assignedTagsToAllChatRooms.indexOf(tagId);
          if (index !== -1) {
            this.assignedTagsToAllChatRooms.splice(index, 1);
          }
        } else {
          await this._tagService.unAssignTags(tagId, selectedProfileIds);
        }
        this.onUnassignTag.emit(tagId);
        this.selectedProfiles.forEach((profile) => {
          profile.tagLinks = profile.tagLinks.filter((tag) => tag.profileTagId !== tagId);
        });
      } else {
        if (this.selectedAll) {
          const excludeProfileIds = getAllChatRoomsCorrespondentProfileIds.filter(
            (item) => !selectedProfileIds.includes(item.correspondentMemberId),
          );
          tagLinks = await this._tagService.assignTagsByFilters(tagId, excludeProfileIds);
          this.assignedTagsToAllChatRooms.push(tagId);
        } else {
          tagLinks = await this._tagService.assignTags(tagId, selectedProfileIds);
        }
        this.selectedProfiles.forEach((profile) => {
          if (profile.id === this._inboxService.selectedChatValue()?.correspondentProfile?.id) {
            profile.tagLinks = [...profile.tagLinks];
          } else {
            const newTagLink = tagLinks.find((t) => t.profileId === profile.id);
            if (newTagLink) {
              profile.tagLinks = [...profile.tagLinks];
            }
          }
        });

        this.onAssignTag.emit(tagLinks);
      }
      this.filterTags();
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Failed to toggle tag', error);
    }
    this.tagUpdatesInProgress = [...this.tagUpdatesInProgress.filter((x) => x !== tagId)];
  }

  chatHasTag(tagId: number): boolean {
    if (this.selectedAll) {
      return this.assignedTagsToAllChatRooms.includes(tagId);
    }
    return (
      this.selectedProfiles?.some((x) => x?.tagLinks?.find((z) => z.profileTagId == tagId)) || false
    );
  }

  getTooltip(displayName: string) {
    return displayName.length > this.approximatedTruncateLength ? displayName : '';
  }

  updateSearchTerm(searchTerm: string | null) {
    this.searchTerm = searchTerm ? searchTerm.trim().toLowerCase() : '';
    this.filterTags();
    this.focusSearchInput();
  }

  filterTags() {
    if (this.searchTerm.trim()) {
      this.filteredTags = this.tags.filter((tag) =>
        tag.displayName.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
    } else {
      this.filteredTags = [...this.tags];
    }
  }

  clearSearch() {
    this.searchFieldComponent.resetValue();
    this.filterTags();
    this.focusSearchInput();
  }

  private focusSearchInput() {
    if (this.searchFieldComponent && this.searchFieldComponent.searchField) {
      this.renderer.selectRootElement(this.searchFieldComponent.searchField.nativeElement).focus();
    }
  }
}
