import { Injectable } from '@angular/core';
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '../session/app-session.service';

import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
  constructor(
    private _permissionChecker: PermissionCheckerService,
    private _router: Router,
    private _sessionService: AppSessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this._sessionService.user) {
      this._router.navigate(['/account/login']);
      return false;
    }

    if (
      (this._sessionService.isAdminTenant || !this._sessionService.isAdminUser) &&
      route.url.map((u) => u.path).includes('billing')
    ) {
      this._router.navigate([this.selectBestRoute()]);
      return false;
    }

    if (this._sessionService.subscription?.appSumo) {
      const urlPaths = route.url.map((u) => u.path);
      if (
        urlPaths.includes('plans-and-subscriptions') ||
        urlPaths.includes('billing-details') ||
        urlPaths.includes('invoices')
      ) {
        this._router.navigate(['/app/billing/app-sumo']);
        return false;
      }
    } else if (route.url.map((u) => u.path).includes('app-sumo')) {
      this._router.navigate(['/app/billing']);
      return false;
    }

    if (!route.data || !route.data['permission']) {
      return true;
    }

    if (this._permissionChecker.isGranted(route.data['permission'])) {
      return true;
    }

    this._router.navigate([this.selectBestRoute()]);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  selectBestRoute(): string {
    if (!this._sessionService.user) {
      return '/account/login';
    }

    if (this._permissionChecker.isGranted('Pages.Users')) {
      return '/app/admin/users';
    }

    return '/app/dashboard';
  }
}

@Injectable()
export class AppAuthenticatedRouteGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _sessionService: AppSessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._sessionService.user) {
      this._router.navigate(['/app/dashboard']);
      return false;
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
