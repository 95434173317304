<ng-container *ngIf="filters">
  <div class="flex gap-x-4 items-center" *ngIf="showButtons">
    <ng-container *ngFor="let filter of filters; let index = index; trackBy: trackByFn">
      <div
        class="flex flex-col sm:flex-row border-secondary-muted text-secondary items-center sm:max-w-none bg-white rounded-t-lg">
        <app-dynamic-field
          [filter]="filter"
          (filterChange)="filterValueChanged($event)"></app-dynamic-field>
      </div>
    </ng-container>
  </div>
  <div
    class="flex items-center w-full gap-x-4"
    [ngClass]="{ 'justify-end': positionTableActionsAt == 'end' }">
    <ng-container *ngFor="let action of actions">
      <button
        heyReachButton
        *ngIf="showButtons || action.showButtonWhenNoData"
        [buttonType]="action.buttonType"
        [disabled]="isDataLoading || action.disabled"
        [matTooltip]="action.disabled ? action.disabledTooltip : ''"
        [attr.title]="action.title"
        (click)="handleActionClick(action)"
        [class.hidden]="action.buttonShouldHide | async">
        <div class="flex items-center gap-x-2">
          <svg-icon [iconPath]="action.icon"></svg-icon>
          {{ action.title }}
        </div>
      </button>
    </ng-container>
  </div>
</ng-container>
