<h2 mat-dialog-title class="text-center">Your account has multiple contracts</h2>
<mat-dialog-content class="bg-white mt-3">
  <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-0 w-160">
    <div class="p-0 pt-2 m-auto col-span-1">
      <img
        src="assets/images/onboarding/multiple.png"
        alt="Add your account"
        class="w-58 mr-auto" />
    </div>
    <div class="p-0 pt-12 mb-auto mx-auto col-span-2">
      <mat-label class="font-semibold text-gray-600">
        Select the contract you want to use:
      </mat-label>
      <mat-form-field class="mt-1 w-full">
        <mat-select placeholder="Available plans" [(ngModel)]="selectedContract">
          <mat-option *ngFor="let contract of contracts; let i = index" [value]="contract">
            {{ contract.contractName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    [disabled]="isSubmitButtonDisabled"
    heyReachButton
    buttonType="default"
    (click)="onSubmitButtonClick()">
    Select
  </button>
</mat-dialog-actions>
