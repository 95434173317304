<div class="flex flex-col gap-y-2">
  <div class="flex flex-col gap-y-1">
    <label class="text-secondary flex flex-col gap-y-1">Your LinkedIn Email address</label>
    <input
      type="email"
      class="rounded-md border-2 border-secondary-muted py-2 px-3 w-full"
      placeholder="LinkedIn email"
      [ngModel]="username"
      (ngModelChange)="onUsernameChange($event)"
      [disabled]="isDisabled" />
  </div>

  <div class="flex flex-col gap-y-1">
    <label class="text-secondary flex flex-col gap-y-1">Your LinkedIn password</label>
    <password-visibility
      [placeholder]="'LinkedIn password'"
      [inputPasswordChange]="password"
      [disableAutofill]="true"
      (passwordChange)="onPasswordChange($event)"></password-visibility>
  </div>

  <div class="flex flex-col">
    <linkedin-inbox-configuration
      [label]="inboxScrapeConfigurationLabel"
      [inboxConfigurations]="inboxConfigurations"
      [inboxPrivacyConfiguration]="inboxScrapeConfiguration"
      (onSelectedInboxOptionChange)="
        onInboxConfigurationChange($event)
      "></linkedin-inbox-configuration>
  </div>

  <app-connect-account-proxy *ngIf="isUsingCustomProxy"></app-connect-account-proxy>

  <div class="flex flex-col mt-2">
    <button
      buttonType="primary"
      heyReachButton
      class="w-full"
      [disabled]="isFormDisabled || isDisabled"
      (click)="onConnectAccount()">
      <div class="flex items-center">
        <mat-icon svgIcon="custom:linkedin" class="icon-size-5"></mat-icon>
        <span class="ml-2">Connect account</span>
      </div>
    </button>
  </div>

  <div class="flex flex-col" *ngIf="!isUsingCustomProxyMandatory">
    <button
      buttonType="default"
      heyReachButton
      class="w-full"
      [disabled]="isDisabled"
      (click)="toggleProxy.emit()">
      <div class="flex items-center">
        <span class="ml-2" *ngIf="!isUsingCustomProxy">Add your own proxy</span>
        <span class="ml-2" *ngIf="isUsingCustomProxy">
          Switch back to {{ companyName }} proxies
        </span>
      </div>
    </button>
  </div>
</div>
