import { CRMServiceProxy, CrmType } from '@shared/service-proxies/service-proxies';
import { Injector } from '@angular/core';

export class IntegrationsShared {
  static crmTypes = [
    { name: 'Hubspot', value: 0, imageUrl: 'assets/images/crms/hubspot.svg' },
    { name: 'Pipedrive', value: 1, imageUrl: 'assets/images/crms/pipedrive.svg' },
    { name: 'GoHighLevel', value: 3, imageUrl: 'assets/images/crms/ghl.svg' },
    { name: 'Close', value: 4, imageUrl: 'assets/images/crms/close.svg' },
  ];

  protected _integrationsService: CRMServiceProxy;

  constructor(injector: Injector) {
    this._integrationsService = injector.get(CRMServiceProxy);
  }

  getCrmTypeImage(crmType: CrmType) {
    return IntegrationsShared.crmTypes.find((x) => x.value == crmType)?.imageUrl;
  }
}
