import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConsts } from '@shared/AppConsts';
import {
  InboxScrapeConfiguration,
  LinkedInAccountServiceProxy,
  OwnProxyDto,
} from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { CustomProxyService } from '@app/linkedin-accounts/connect-account/custom-proxy-service';
import { getInboxConfigurations } from '../connect-account-consts';
import { WhiteLabelService } from '@app/white-label.service';
import { AppSessionService } from '@shared/session/app-session.service';
import { LinkedInAccountsService } from '@app/linkedin-accounts/linked-in-accounts.service';
import { Observable } from 'rxjs';

declare var window: any;

@Component({
  selector: 'connect-account-extension',
  templateUrl: 'connect-account-extension.component.html',
})
export class ConnectAccountExtensionComponent implements OnInit, OnDestroy {
  @Input() isUsingCustomProxyMandatory: boolean = false;
  @Input() isUsingCustomProxy: boolean = false;
  @Output() toggleProxy = new EventEmitter<void>();
  accountProfile: any;
  accountAlreadyConnected$: Observable<boolean> =
    this.linkedInAccountsService.extensionAccountAlreadyConnected$;
  hasExtension: boolean = false;
  detectExtensionInterval: any;
  isLoadingAccountProfile: boolean = false;
  companyName = this._whiteLabelService.companyName;
  isExtensionConnecting = false;
  inboxConfigurations;
  isAgency: boolean = this.appSession.subscription?.subscriptionType?.isAgency;
  inboxScrapeConfigurationLabel: string;

  constructor(
    private linkedInAccountServiceProxy: LinkedInAccountServiceProxy,
    private _toaster: MatSnackBar,
    public _customProxyService: CustomProxyService,
    private _whiteLabelService: WhiteLabelService,
    private appSession: AppSessionService,
    private linkedInAccountsService: LinkedInAccountsService,
  ) {
    this._customProxyService.updateCustomProxyState(new OwnProxyDto());
    this.inboxConfigurations = getInboxConfigurations(this._whiteLabelService.companyName);
    this.inboxScrapeConfigurationLabel = `Select which conversations to load into ${this._whiteLabelService.companyName}`;
  }

  ngOnInit(): void {
    this.detectHeyReachWidget();
    this.detectExtensionInterval = setInterval(() => this.detectHeyReachWidget(), 1000);
  }

  ngOnDestroy(): void {
    if (this.detectExtensionInterval) {
      clearInterval(this.detectExtensionInterval);
    }
  }

  changeLinkedInAccount() {
    window.chrome.runtime?.sendMessage(AppConsts.extensionId, 'changeLiAccount', () => {});
  }

  connectAccountViaExtension() {
    if (!this.accountProfile) {
      return;
    }

    this.linkedInAccountsService.connectAccountViaExtension().subscribe();
  }

  detectHeyReachWidget(): void {
    // Check if the user has installed the extension
    if (!window.chrome.runtime) {
      this.hasExtension = false;
    }

    window.chrome.runtime?.sendMessage(AppConsts.extensionId, 'version', (res) => {
      if (this.hasExtension != !!res) {
        this.hasExtension = !!res;
        if (this.hasExtension) {
          this.openLoginConfirmation();
        }
      }
    });
  }

  openExtensionInStore(): void {
    window.open(AppConsts.extensionDownloadUrl, '_blank');
  }

  openLoginConfirmation() {
    if (this.hasExtension) {
      this.isLoadingAccountProfile = true;
      window.chrome.runtime?.sendMessage(AppConsts.extensionId, 'getLinkedInProfile', (res) => {
        if (res.success) {
          this.linkedInAccountServiceProxy
            .getValidLinkedInAccountsForTenant()
            .pipe(
              finalize(() => {
                this.isLoadingAccountProfile = false;
              }),
            )
            .subscribe((res1) => {
              this.linkedInAccountsService.extensionAccountAlreadyConnected = res1.some(
                (x) => x.linkedInUserProfile?.id === res.result?.memberId,
              );
              this.accountProfile = res.result;
              this.linkedInAccountsService.updateConnectAccountExtensionInput(res.result);
            });
        } else if (res.errorStatus == 401 || res.errorStatus == 403) {
          window.open('https://linkedin.com', '_blank');
          this.isLoadingAccountProfile = false;
        } else {
          this.isLoadingAccountProfile = false;
          this._toaster.open(
            res.errorMessage || 'Something went wrong please try again',
            undefined,
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3500,
            },
          );
        }
      });
    }
  }

  resetProfileAndFields() {
    this.linkedInAccountsService.updateConnectAccountExtensionInput(undefined);
    this.accountProfile = undefined;
    this.linkedInAccountsService.extensionAccountAlreadyConnected = false;
  }

  loginWithLinkedIn() {
    this.resetProxyState();
    this.resetProfileAndFields();
    this.linkedInAccountsService.changeLoginScreen('manual-login');
  }

  resetProxyState() {
    this._customProxyService.resetState();
    this._customProxyService.resetProxyErrorMessage();
  }

  selectedInboxOptionChange(inboxConfig: InboxScrapeConfiguration) {
    this.accountProfile.inboxScrapeConfiguration = inboxConfig;
    this.accountProfile = { ...this.accountProfile, inboxScrapeConfiguration: inboxConfig };
    this.linkedInAccountsService.updateConnectAccountExtensionInput({
      ...this.accountProfile,
      inboxScrapeConfiguration: inboxConfig,
    });
  }
}
