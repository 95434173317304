import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { CustomProxyService } from '@app/linkedin-accounts/connect-account/custom-proxy-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '@shared/components/error-snackbar/error-snackbar.component';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WhiteLabelService } from '@app/white-label.service';
import { debounceTime } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';
import { FormsModule } from '@angular/forms';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';

@Component({
  imports: [CommonModule, MaterialModule, FormsModule, HeyReachButtonDirective],
  selector: 'app-proxy-select',
  templateUrl: './proxy-selection.component.html',
  animations: [accountModuleAnimation()],
  standalone: true,
})
export class ProxySelectionComponent implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();

  companyName = this.whiteLabelService.companyName;
  constructor(
    public _customProxyService: CustomProxyService,
    private _toaster: MatSnackBar,
    private whiteLabelService: WhiteLabelService,
    private _changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscribeToErrorNotifications();
    this.subscribeToStateChange();
  }

  subscribeToStateChange() {
    this._customProxyService.state$
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((_) => {
        this._changeDetectionRef.markForCheck();
      });
    this._customProxyService.proxyVerifiedNotification$
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((_) => {
        this._changeDetectionRef.markForCheck();
      });
    this._customProxyService.proxyVerifiedErrorNotification$
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((_) => {
        this._changeDetectionRef.markForCheck();
      });
  }

  subscribeToErrorNotifications() {
    this._customProxyService.proxyVerifiedErrorNotification$
      .pipe(
        filter((x) => x != null && x != ''),
        takeUntil(this.destroy$),
      )
      .subscribe((errorMessage) => {
        const snackbar = this._toaster.openFromComponent(ErrorSnackbarComponent, {
          duration: 5000,
          panelClass: 'error-snackbar',
        });
        snackbar.instance.message = errorMessage;
      });
  }

  isFormFilled() {
    return this._customProxyService.proxyHost && this._customProxyService.proxyPort;
  }

  verifyCustomProxy(): void {
    this._customProxyService.testCustomProxy();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
