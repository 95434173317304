import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { PasswordVisibilityComponent } from '@shared/components/toggle-password-visibility/password-visibility.component';
import { InboxScrapeConfiguration } from '@shared/service-proxies/service-proxies';
import { MatIconModule } from '@angular/material/icon';
import { LinkedInInboxConfigurationComponent } from '@shared/components/linkedin/inbox-configuration/inbox-configuration.component';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';
import { ConnectAccountProxyComponent } from '../connect-account-proxy/connect-account-proxy.component';

@Component({
  imports: [
    FormsModule,
    PasswordVisibilityComponent,
    NgIf,
    MatIconModule,
    LinkedInInboxConfigurationComponent,
    HeyReachButtonDirective,
    ConnectAccountProxyComponent,
  ],
  selector: 'app-connect-account-manual',
  templateUrl: './connect-account-manual.component.html',
  standalone: true,
})
export class ConnectAccountManualComponent implements OnChanges {
  @Input() username: string;
  @Input() password: string;
  @Input() inboxScrapeConfiguration: InboxScrapeConfiguration;
  @Input() isDisabled: boolean = false;
  @Input() inboxScrapeConfigurationLabel: string;
  @Input() inboxConfigurations: { value: InboxScrapeConfiguration; text: string }[];
  @Input() isUsingCustomProxy: boolean = false;
  @Input() isUsingCustomProxyMandatory: boolean = false;
  @Input() isProxyValidated: boolean = false;
  @Input() companyName: string;

  @Output() usernameChange = new EventEmitter<string>();
  @Output() passwordChange = new EventEmitter<string>();
  @Output() inboxConfigurationChange = new EventEmitter<InboxScrapeConfiguration>();
  @Output() connectAccount = new EventEmitter<void>();
  @Output() toggleProxy = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isUsingCustomProxyMandatory && this.isUsingCustomProxyMandatory) {
      this.isUsingCustomProxy = true;
    }
  }

  get isFormDisabled(): boolean {
    const isProxyValid = this.isUsingCustomProxy ? this.isProxyValidated : true;
    const isFormValid =
      !!this.username && !!this.password && this.inboxScrapeConfiguration !== undefined;
    return !isFormValid || !isProxyValid;
  }

  onUsernameChange(username: string): void {
    this.usernameChange.emit(username);
  }

  onPasswordChange(password: string): void {
    this.passwordChange.emit(password);
  }

  onInboxConfigurationChange(config: InboxScrapeConfiguration): void {
    this.inboxConfigurationChange.emit(config);
  }

  onConnectAccount(): void {
    this.connectAccount.emit();
  }

  onToggleProxy(): void {
    this.toggleProxy.emit();
  }
}
