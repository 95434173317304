<h3 class="text-lg font-semibold mb-3">How do you want to connect your LinkedIn account?</h3>
<button
  class="flex flex-row border-primary bg-primary-muted border-2 rounded-lg px-4 py-9 gap-x-4 w-full"
  (click)="screenToBeDisplayed.emit('2fa-prompt')">
  <mat-icon class="mt-2 text-primary icon-size-10" svgIcon="custom:login-infinite"></mat-icon>
  <div class="flex flex-col justify-start gap-y-2 flex-1">
    <div class="flex flex-col gap-y-1">
      <div class="flex justify-between items-center">
        <h5 class="font-bold text-lg text-left">Infinite Login</h5>
        <span class="bg-primary text-light text-base font-medium px-4 py-1 rounded-full">
          Your Best Choice
        </span>
      </div>
      <h6 class="font-medium text-left">LinkedIn Credentials + 2FA</h6>
    </div>
    <p class="text-left text-normal mt-2">
      Select this option to keep your LinkedIn account always connected. No more disconnection
      issues.
    </p>
  </div>
</button>

<button
  class="flex flex-row border-pale border-2 rounded-lg px-4 py-9 gap-x-4 hover:border-primary w-full mt-4"
  (click)="screenToBeDisplayed.emit('manual-login')">
  <mat-icon class="text-primary icon-size-10" svgIcon="custom:login-manual"></mat-icon>
  <div class="flex flex-col justify-start gap-2">
    <h5 class="font-bold text-lg text-left">Credentials Login</h5>
    <h6 class="font-medium text-left">Login securely using your LinkedIn account credentials.</h6>
  </div>
</button>

<button
  *ngIf="!isWhiteLabel"
  class="flex flex-row border-pale border-2 rounded-lg px-4 py-9 gap-x-4 hover:border-primary w-full mt-4"
  (click)="screenToBeDisplayed.emit('extension-login')">
  <mat-icon class="text-primary icon-size-10" svgIcon="custom:login-extension"></mat-icon>
  <div class="flex flex-col justify-start gap-2">
    <h5 class="font-bold text-lg text-left">Login with extension</h5>
    <h6 class="font-medium text-left">
      Quickly access HeyReach by connecting your account directly (using the LinkedIn session that's
      in this browser)
    </h6>
  </div>
</button>
