<div *ngIf="_customProxyService.isUsingCustomProxy" class="flex flex-col">
  <div
    *ngIf="!isAgency"
    class="flex py-3 px-3 mb-2 text-md bg-sand-muted rounded-lg text-sand"
    role="alert">
    <mat-icon
      class="icon-size-5 relative top-1 text-sand"
      svgIcon="heroicons_solid:warning-triangle"></mat-icon>
    <span class="mt-1 ml-2">
      <p class="text-sm font-medium">
        Adding your own proxy can affect your overall {{ companyName }}
        experience. Proceed only if you know what you are doing.
      </p>
    </span>
  </div>
  <span class="font-medium mt-3 mb-2 text-secondary text-md text-left">Verify your proxy</span>
  <app-proxy-select></app-proxy-select>
</div>
