import { Pipe, PipeTransform } from '@angular/core';
import { ConnectAccountConsts } from '@app/linkedin-accounts/connect-account/connect-account-consts';
import { ConnectLinkedInAccountInputDto } from '@shared/service-proxies/service-proxies';

/**
 * This Pipe is used for both Connect Account form for LinkedIn and Custom Proxies.
 * isExtensionMode: Is the first check where we determine if the pipe is used if you are connecting your account with the HeyReach Extension
 * isCustomProxy && isVerificationStatus !== 'success': Is the extra check for all cases,
 * to disable the Connect button if they have switched on using custom proxies, and the proxy is not verified and tested successfully
 */
@Pipe({
  name: 'isConnectAccountFormDisabledPipe',
})
export class ConnectAccountFormStatePipe implements PipeTransform {
  transform(
    newAccountInput?: ConnectLinkedInAccountInputDto,
    isCustomProxy?: boolean,
    isVerificationStatus?: string,
    is2faSkipped?: boolean,
    isExtensionMode?: boolean,
    isExtensionConnecting?: boolean,
  ): boolean {
    let isConnectAccountFormDisabled = false;

    if (isCustomProxy && isVerificationStatus !== 'success') {
      return true;
    }

    if (!isExtensionMode && newAccountInput) {
      isConnectAccountFormDisabled =
        !ConnectAccountConsts.isAccountAndInboxValid(newAccountInput) ||
        (!is2faSkipped && !ConnectAccountConsts.is2faValid(newAccountInput));
    } else {
      isConnectAccountFormDisabled =
        newAccountInput?.inboxScrapeConfiguration === undefined || isExtensionConnecting;
    }

    return isConnectAccountFormDisabled;
  }
}
