<dialog-container (closeClick)="close()" [closeDisabled]="isLoading">
  <dialog-header title="Add leads to list."></dialog-header>

  <p class="font-bold text-secondary mb-2">
    {{ getHint }}
  </p>

  <ng-container *ngIf="shouldAddLeadsToNewList">
    <mat-form-field class="w-full">
      <input matInput [(ngModel)]="newListName" placeholder="New list name" />
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!shouldAddLeadsToNewList">
    <list-select [listType]="0" [(selectedList)]="selectedList"></list-select>
  </ng-container>

  <p class="font-semibold">
    or&nbsp;
    <button class="text-primary" (click)="shouldAddLeadsToNewList = !shouldAddLeadsToNewList">
      {{ getToggleButtonText }}
    </button>
  </p>

  <div class="flex">
    <div class="flex-1"></div>

    <button
      heyReachButton
      buttonType="primary"
      [disabled]="isSubmitButtonDisabled"
      (click)="onSubmitClick()">
      <span [loading]="isLoading">Add leads</span>
    </button>
  </div>
</dialog-container>
