<form class="form-horizontal flex flex-col h-full" autocomplete="off">
  <!-- Modal Header -->
  <div class="py-4 px-6 flex items-center">
    <h2 class="text-xl font-semibold flex-1">
      {{ isEditMode ? 'Modify Tag' : 'Create a new Tag' }}
    </h2>
    <button type="button" class="ml-auto" (click)="close()">
      <svg
        class="h-6 w-6 text-gray-600 hover:text-gray-800"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>

  <!-- Modal Body -->
  <div class="modal-body flex-grow flex items-start">
    <mat-form-field class="w-full md:w-2/3 px-2 py-2">
      <mat-icon
        [style.color]="model.colorHex"
        svgIcon="heroicons_outline:tag"
        class="mr-2"></mat-icon>
      <mat-label class="font-semibold text-gray-600">Your tag name:</mat-label>
      <input
        matInput
        placeholder="Label title"
        (click)="$event.stopPropagation()"
        name="labelName"
        [(ngModel)]="model.displayName" />
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/3 px-2 py-2">
      <mat-label class="font-semibold text-gray-600">Color:</mat-label>
      <mat-select
        [disableOptionCentering]="true"
        matPrefix
        [(value)]="model.colorHex"
        class="w-full">
        <mat-select-trigger class="h-6 w-full">
          <div class="flex items-center">
            <div class="w-6 h-6 rounded-full mr-2" [style.background-color]="model.colorHex"></div>
          </div>
        </mat-select-trigger>

        <div class="flex flex-wrap w-full my-2 mx-2 -mr-2">
          <ng-container *ngFor="let color of labelColors">
            <mat-option
              class="relative flex w-12 h-12 p-0 cursor-pointer rounded-full bg-transparent"
              [value]="color"
              #matOption="matOption">
              <span class="flex w-10 h-10 m-1 rounded-full" [style.background-color]="color"></span>
            </mat-option>
          </ng-container>
        </div>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Modal Footer -->
  <div class="py-4 px-6 mt-auto flex justify-between items-center">
    <button
      class="hover:text-accent text-darkgray text-base md:text-lg px-4 py-2 w-full"
      heyReachButton
      buttonType="default"
      (click)="close()">
      Discard
    </button>
    <div class="w-40"></div>
    <button
      class="hover:text-accent text-darkgray text-base md:text-lg px-4 py-2 w-full"
      heyReachButton
      [disabled]="isSaveButtonDisabled || saving"
      (click)="save()">
      {{ isEditMode ? 'Save Changes' : 'Create Tag' }}
    </button>
  </div>
</form>
