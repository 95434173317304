import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouteGuard } from '@shared/auth/auth-route-guard';

const routes: Routes = [
  {
    path: 'maintenance',
    loadChildren: () => import('maintenance/maintenance.module').then((m) => m.MaintenanceModule), // Lazy load maintenance module
    data: { preload: false },
  },
  {
    path: 'embed',
    loadChildren: () => import('iframe/iframe.module').then((m) => m.IframeModule), // Lazy load iframe module
    data: { preload: true },
  },
  {
    path: 'account',
    loadChildren: () => import('account/account.module').then((m) => m.AccountModule), // Lazy load account module
    data: { preload: false },
  },
  {
    path: 'app',
    loadChildren: () => import('app/app.module').then((m) => m.AppModule), // Lazy load app module
    data: { preload: false },
    canActivate: [AppRouteGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('administration/administration.module').then((m) => m.AdministrationModule), // Lazy load admin module
    data: { preload: false },
    canActivate: [AppRouteGuard],
  },
  { path: '**', redirectTo: '/app/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class RootRoutingModule {}
