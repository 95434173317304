import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';
import { DisplayedScreenInLogin } from '@app/linkedin-accounts/linked-in-accounts.service';
import { MaterialModule } from '@shared/material.module';
import { FormsModule } from '@node_modules/@angular/forms';
import { WhiteLabelService } from '@app/white-label.service';

@Component({
  imports: [CommonModule, HeyReachButtonDirective, MaterialModule, FormsModule],
  selector: 'app-2fa-confirmation',
  templateUrl: '2fa-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'flex flex-col gap-y-8 items-center',
  },
})
export class TwoFactorAuthConfirmationComponent {
  @Output() screenToBeDisplayed: EventEmitter<DisplayedScreenInLogin> =
    new EventEmitter<DisplayedScreenInLogin>();

  userConfirmsTheyUsedAuthenticatorApp: boolean = false;
  userConfirmsTheyHaveTheir2faKey: boolean = false;

  protected isWhiteLabel: boolean = this._whiteLabelService.notUs;

  constructor(private _whiteLabelService: WhiteLabelService) {}
}
