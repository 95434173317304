<ng-container *ngIf="profile && getLinkedinProfileUrl()">
  <a
    class="hover:bg-gray-100 hover:text-primary text-gray-600 p-2 rounded-full"
    target="_blank"
    [href]="getLinkedinProfileUrl()"
    matTooltip="View Profile on LinkedIn">
    <mat-icon
      class="icon-size-4 relative top-0.5 text-primary"
      svgIcon="custom:linkedin-premium"></mat-icon>
  </a>
</ng-container>
