import { Injectable } from '@angular/core';

@Injectable()
export class ColorService {
  /**
   * Generate a lighter version of the given hex color.
   * @param color The original color in hex format (e.g., #1C64F2).
   * @returns The lighter color in hex format.
   */
  generateLighterColor(color: string): string {
    // Convert hex color to RGB
    const rgb = this.hexToRgb(color);
    if (!rgb) {
      throw new Error('Invalid hex color format');
    }

    // Lighten the color
    const lightenedRgb = this.lightenColor(rgb, 0.9);

    // Convert back to hex
    return this.rgbToHex(lightenedRgb);
  }

  /**
   * Convert a hex color to an RGB object.
   * @param hex The hex color string.
   * @returns An object with r, g, b values.
   */
  private hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  /**
   * Lighten an RGB color by a given percentage.
   * @param rgb The RGB color object.
   * @param factor The lightening factor (0 to 1).
   * @returns The lightened RGB color object.
   */
  private lightenColor(
    rgb: { r: number; g: number; b: number },
    factor: number,
  ): { r: number; g: number; b: number } {
    return {
      r: Math.min(255, Math.round(rgb.r + (255 - rgb.r) * factor)),
      g: Math.min(255, Math.round(rgb.g + (255 - rgb.g) * factor)),
      b: Math.min(255, Math.round(rgb.b + (255 - rgb.b) * factor)),
    };
  }

  /**
   * Convert an RGB object to a hex color string.
   * @param rgb The RGB color object.
   * @returns The hex color string.
   */
  private rgbToHex(rgb: { r: number; g: number; b: number }): string {
    return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`;
  }
}
