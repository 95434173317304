import { Pipe, PipeTransform } from '@angular/core';
import {
  LinkedInAccountSlotDto,
  LinkedInAccountState,
  LinkedInAccountStateReason,
  RecruiterState,
  SalesNavigatorState,
} from '@shared/service-proxies/service-proxies';
import {
  AccountActionData,
  AccountActionType,
} from '@app/linkedin-accounts/linkedin-accounts.component';

@Pipe({
  name: 'accountActionButton',
  pure: true,
})
export class AccountActionButtonPipe implements PipeTransform {
  transform(slot: LinkedInAccountSlotDto): AccountActionData[] {
    return !slot.isActive
      ? [accountActions['reactivate']]
      : stateMappings(
          slot.linkedInAccount.accountStateReason,
          slot.linkedInAccount.salesNavigatorState,
          slot.linkedInAccount.recruiterState,
          slot.linkedInAccount.hasNavigator,
          slot.linkedInAccount.hasRecruiter,
        )[slot.linkedInAccount.accountState];
  }
}

const accountActions: Record<AccountActionType, AccountActionData> = {
  enterPin: {
    icon: './../../assets/icons/accounts-icons/enter-pin-icon.svg',
    text: 'Enter PIN',
    action: 'enterPin',
    disabled: false,
    disabledTooltip: '',
  },
  reconnect: {
    icon: './../../assets/icons/accounts-icons/reconnect-icon.svg',
    text: 'Re-connect',
    action: 'reconnect',
    disabled: false,
    disabledTooltip: '',
  },
  manageLimits: {
    icon: './../../assets/icons/settings-icon.svg',
    text: 'Configure limits',
    action: 'manageLimits',
    disabled: false,
    disabledTooltip: '',
  },
  reactivate: {
    text: 'Re-activate',
    icon: 'assets/icons/accounts-icons/reactivate-icon.svg',
    action: 'reactivate',
    disabled: false,
    disabledTooltip: '',
  },
  reSync: {
    icon: './../../assets/icons/accounts-icons/re-sync-icon.svg',
    text: 'Re-sync',
    action: 'reSync',
    disabled: false,
    disabledTooltip: '',
  },
  reLogNavigator: {
    icon: './../../assets/icons/accounts-icons/re-log-plan-icon.svg',
    text: 'Connect Sales Navigator',
    action: 'reLogNavigator',
    disabled: false,
    disabledTooltip: '',
  },
  reLogRecruiter: {
    icon: './../../assets/icons/accounts-icons/re-log-plan-icon.svg',
    text: 'Connect Recruiter',
    action: 'reLogRecruiter',
    disabledTooltip: '',
    disabled: false,
  },
  sync: null,
  manageSchedule: null,
  inboxScrape: null,
  configureProxy: null,
  disconnect: null,
};

const stateReasonMappings: Record<LinkedInAccountStateReason, AccountActionData[]> = {
  [LinkedInAccountStateReason._0]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._1]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._2]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._3]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._4]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._5]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._6]: [accountActions['enterPin']],
  [LinkedInAccountStateReason._7]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._8]: [accountActions['enterPin']],
  [LinkedInAccountStateReason._9]: [accountActions['enterPin']],
  [LinkedInAccountStateReason._10]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._11]: [accountActions['enterPin']],
  [LinkedInAccountStateReason._12]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._13]: null,
  [LinkedInAccountStateReason._14]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._15]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._16]: null,
  [LinkedInAccountStateReason._17]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._18]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._19]: [accountActions['reconnect']],
  [LinkedInAccountStateReason._20]: [accountActions['reconnect']],
};

function stateMappings(
  stateReason: LinkedInAccountStateReason,
  salesNavigatorState: SalesNavigatorState,
  recruiterState: RecruiterState,
  hasNavigator: boolean,
  hasRecruiter: boolean,
): Record<LinkedInAccountState, AccountActionData[]> {
  return {
    [LinkedInAccountState._0]: null,
    [LinkedInAccountState._1]: [
      accountActions['manageLimits'],
      ...(hasNavigator && salesNavigatorState == SalesNavigatorState._2
        ? [accountActions['reLogNavigator']]
        : []),
      ...(hasRecruiter && recruiterState == RecruiterState._2
        ? [accountActions['reLogRecruiter']]
        : []),
    ],
    [LinkedInAccountState._2]: stateReasonMappings[stateReason],
    [LinkedInAccountState._3]: stateReasonMappings[stateReason],
    [LinkedInAccountState._4]: null,
    [LinkedInAccountState._5]: null,
    [LinkedInAccountState._6]: null,
    [LinkedInAccountState._7]: [accountActions['reSync']],
  };
}
