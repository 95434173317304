<div class="flex items-center justify-between mb-2">
  <div class="flex-1 text-xl mt-4 mb-2 font-semibold ml-7">
    {{ title }}
  </div>

  <div class="self-end mr-5">
    <button heyReachButton buttonType="icon" (click)="onCloseButtonClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</div>

<div class="mb-2 border-b-2 border-secondary-muted"></div>
